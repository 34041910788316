import ContainerMedia from "Components/ContainerMedia/ContainerMedia";
import InfoContactPage from "./InfoContactPage";
import "./FormContactPage.css"
import FormContact from "Shared/ContactUs/Components/FormContact";
import { useTranslation } from "react-i18next";
const FormContactPage = () => {
  // FOR TRANSLATION AR && EN
  const {t}=useTranslation()
  return (
    <div className="form-contact-page padding-top-50">
      {/* ============ START CONTAINER =========== */}
      <ContainerMedia>
        {/* =========== START ROW =========== */}
        <div className="row g-3">
          {/* ============ START COL ============= */}
          <div className="col-12 col-md-6">
            {/* ============== START INFO CONTACT ============ */}
            <InfoContactPage />
            {/* ============== END INFO CONTACT ============ */}
          </div>
          {/* ============ END COL ============= */}
          {/* ============ START COL ============= */}
          <div className="col-12 col-md-6">
            {/* ============= START FORM INFO ============ */}
            <FormContact titleTop={t("send a message")} />
            {/* ============= END FORM INFO ============ */}
          </div>
          {/* ============ END COL ============= */}
        </div>
        {/* =========== END ROW =========== */}
      </ContainerMedia>
      {/* ============ END CONTAINER =========== */}
    </div>
  );
};

export default FormContactPage;
