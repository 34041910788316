import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import FilterCategory from "Components/Ui/FilterCategory/FilterCategory";
import AllInfoResults from "./Components/AllInfoResults/AllInfoResults";
import "./Results.css";
import ContainerMedia from "Components/ContainerMedia/ContainerMedia";
import { useTranslation } from "react-i18next";
import usePropertyFilters from "hooks/usePropertyFilters";
import usePaginationParams from "hooks/usePaginationParams";
import useProperties from "hooks/useProperties";
const Results = () => {
  // TRANSLATION AR && EN
  const { i18n } = useTranslation();
  const{orderBy,bedNum,city,highestPrice,lowestPrice,type,handleOrderByChange} = usePropertyFilters();
  const { page, handleChangePage ,rowsPerPage,handleChangeRowsPerPage}=usePaginationParams()
  const{ properties, loading, error,count,updateSearchResults }= useProperties({orderBy,rowsPerPage,page,bedNum,city,highestPrice,lowestPrice,type})
  return (
    <>
      <HelmetInfo titlePage={i18n.language === "ar" ? "النتائج" : "results"} />
      <div className="results-page">
      
        <header>
          <ContainerMedia>
            <div className="filter-header-info">
              <FilterCategory
               bedNum={bedNum}
               city={city}
               highestPrice={highestPrice}
               lowestPrice={lowestPrice}
               type={type}
               showSearchResults={updateSearchResults}/>
            </div>
          </ContainerMedia>
        </header>
      
        <main>
          <AllInfoResults
           handleOrderByChange={handleOrderByChange}
           rowsPerPage={rowsPerPage} page={page} 
           handleChangePage={handleChangePage} count={count}
           properties={properties} loading={loading}/>
         
        </main>
       
      </div>
    </>
  );
};

export default Results;
