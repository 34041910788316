import ContainerMedia from "../../Components/ContainerMedia/ContainerMedia";
import { useEffect, useState } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { Link, NavLink, useLocation } from "react-router-dom";
import "./NavbarMenu.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import ArrowIcon from "../../assets/images/iconsSvg/ArrowIcon";
import LogoNavbar from "Components/LogoInfo/LogoNavbar";
import LanguagesDropMenu from "Components/Ui/LanguagesDropMenu/LanguagesDropMenu";
import LogoBlue from "Components/LogoInfo/LogoBlue";
import { useTranslation } from "react-i18next";
const NavbarMenu = () => {
  const { t } = useTranslation();

  // FIXED TOP NAVBAR
  const [isMenuFixed, setMenuFixed] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY; // GET SCROL WINDOW HEIGHT
      const shouldBeFixed = scrollTop > 100; // HERE TO TO CONDITION IF SCROLL WINDOW > 100
      setMenuFixed(shouldBeFixed); // SET FIXED NAVBAR
    };
    window.addEventListener("scroll", handleScroll);
  }, []);

  // SHOW SIDEBAR ON SCREEN "991PX"
  const [showSidebar, setShowSidebar] = useState(false);

  //  TOGGLE SIDBAR
  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  //  CLOSE SIDEBAR
  const closeSidebar = () => {
    setShowSidebar(false);
  };

  useEffect(() => {
    window.onscroll = () => {
      setShowSidebar(false); // IF WINDOW SCROLL HIDE SIDEBAR "CLOSE"
    };
  }, []);

  // CHECK LOCATION
  const location = useLocation();

  useEffect(() => {
    // HERE I ADD CLASS TO HOME PAGE TO NAVBAR BACKGROUND TRANSPARENT IN HOME PAGE
    const navbar = document.querySelector(".home-navbar");
    if (location.pathname === "/") {
      // IF LOCATION PATH NAME ==== "/" HOME PAGE
      navbar.classList.add("home-bg"); // ADD CLASS "HOME-BG" TO NAVBAR
    } else {
      //ELSE NOT HOME PAGE "/"
      navbar.classList.remove("home-bg"); // REMOVE CLASS "HOME-BG" FROM NAVBAR
    }
  }, [location]);

  return (
    <div className={"home-navbar"}>
      <Navbar
        expand="lg"
        className={`navbar-menu z-3  ${isMenuFixed ? "menu-fixed" : ""}`}
      >
        <ContainerMedia>
          <Navbar.Brand data-aos="fade-left">
            {location.pathname === "/" ? (
              isMenuFixed ? (
                <LogoBlue />
              ) : (
                <LogoNavbar />
              )
            ) : (
              <LogoBlue />
            )}
          </Navbar.Brand>

          {/* ========= START MAIN LEFT ========= */}
          <div
            className="main-info-left d-flex align-items-center gap-3"
            data-aos="fade-right"
          >
            <LanguagesDropMenu />
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              onClick={toggleSidebar}
            />
          </div>
          {/* ========= END MAIN LEFT ========= */}

          <div
            onClick={closeSidebar}
            className={`all-main-navbar ${
              showSidebar === true ? "show-main" : ""
            }`}
          >
            <div
              className={`overlay-menu   ${
                showSidebar === true ? "show-over" : ""
              }`}
              onClick={closeSidebar}
            />

            <Navbar.Collapse
              id="basic-navbar-nav"
              className={`nav-menu ${showSidebar === true ? "show--1" : ""}`}
            >
              <div onClick={closeSidebar} className="icon-close-menu">
                <FontAwesomeIcon icon={faTimes} />
              </div>
              <Nav className="ms-auto  gap-5" data-aos="fade-right">
                <NavLink className={"nav-link "} to="/properties">
                  {t("properties")}
                </NavLink>
                <NavLink className={"nav-link "} to="/about">
                  {t("about")}
                </NavLink>
                <NavLink className={"nav-link "} to="/faq">
                  {t("FAQ")}
                </NavLink>
              </Nav>
              <div className="left-nav-menu d-flex align-items-center  gap-4">
                <LanguagesDropMenu />
                <Link to="/contactUsPage" className="btn-main btn-transparent">
                  {t("contact")} <ArrowIcon />
                </Link>
              </div>
            </Navbar.Collapse>
          </div>
        </ContainerMedia>
      </Navbar>
    </div>
  );
};

export default NavbarMenu;
