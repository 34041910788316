import ContainerMedia from "../../Components/ContainerMedia/ContainerMedia";
import HelmetInfo from "../../Components/HelmetInfo/HelmetInfo";
import SliderBanner from "./Components/SliderBanner/SliderBanner";
import CardsInfo from "./Components/CardsInfo/CardsInfo";
import BannerHome from "./Components/BannerHome/BannerHome";
import CardsHowWork from "./Components/CardsHowWork/CardsHowWork";
import ContactUs from "Shared/ContactUs/ContactUs";
import { useTranslation } from "react-i18next";
import useProperties from "hooks/useProperties";

const Home = () => {
  // TRANSLATION AR && EN
  const { i18n } = useTranslation();
  const{ properties, loading ,showSearchResults}= useProperties({rowsPerPage: 4})

  return (
    <>
      <HelmetInfo
        titlePage={i18n.language === "ar" ? "الصفحة الرئيسية" : "Home"}
      />
      <header>
        <SliderBanner showSearchResults={showSearchResults}/>
      </header>
      <main>
        <ContainerMedia>
        <CardsInfo properties={properties} loading={loading}/>
        <BannerHome />
        <CardsHowWork />
        <ContactUs />
        </ContainerMedia>
      </main>
    </>
  );
};

export default Home;
