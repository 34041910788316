import { useEffect, useState } from "react";
import "./ButtonScroll.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowUp } from "@fortawesome/free-solid-svg-icons";

const ButtonScroll = () => {
  // ADD ACTIVE FOR BUTTON SCROLL
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    // GET WINDOW SCROLL HEIGHT
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const scrollThreshold = 200;

      if (scrollPosition > scrollThreshold) {
        // SCROLL WINDOW HEIGHT > 200PX
        setIsActive(true); // ADD ACTIVE CLASS "TRUE" TO SHOW BUTTON SCROLL
      } else {
        setIsActive(false); // ELSE REMOVE CLASS ACTIVE "FALSE" TO HIDE BUTTON SCROLL
      }
    };

    window.addEventListener("scroll", handleScroll); // ADD FUNCTION SCROLL ON WINDOW SCROLL

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    // ONCL IKE BUTTON SCROLL TO TOP SMOOTH
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  return (
    <>
      <button
        onClick={scrollToTop}
        className={`btn-scroll ${isActive ? "active" : ""}`}
      >
        <FontAwesomeIcon icon={faLongArrowUp} />
      </button>
    </>
  );
};

export default ButtonScroll;
