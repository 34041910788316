import * as Yup from 'yup';

export const getValidationSchema = (t) => Yup.object().shape({
  name: Yup.string()
    .required(t('name is required'))
    .min(2, t('2 name characters')),
  phone: Yup.string().required(t('phone is required')),
  message: Yup.string()
    .required(t('message is required'))
    .min(10, t('message 10 characters')),
});
