import React from 'react'

const BedIcons = () => {
  return (
    <><svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.6654 13.832V12.832M3.33203 13.832V12.832" stroke="#212121" stroke-linecap="round"/>
    <path d="M1.33203 10.5C1.33203 9.87874 1.33203 9.56812 1.43353 9.32309C1.56885 8.99638 1.82842 8.73682 2.15512 8.60149C2.40015 8.5 2.71078 8.5 3.33203 8.5H12.6654C13.2866 8.5 13.5972 8.5 13.8423 8.60149C14.169 8.73682 14.4285 8.99638 14.5639 9.32309C14.6654 9.56812 14.6654 9.87874 14.6654 10.5C14.6654 11.1213 14.6654 11.4319 14.5639 11.6769C14.4285 12.0036 14.169 12.2632 13.8423 12.3985C13.5972 12.5 13.2866 12.5 12.6654 12.5H3.33203C2.71078 12.5 2.40015 12.5 2.15512 12.3985C1.82842 12.2632 1.56885 12.0036 1.43353 11.6769C1.33203 11.4319 1.33203 11.1213 1.33203 10.5Z" stroke="#212121"/>
    <path d="M14 8.5013C14 5.98714 14 4.73007 13.219 3.94902C12.4379 3.16797 11.1808 3.16797 8.66667 3.16797H7.33333C4.81918 3.16797 3.5621 3.16797 2.78105 3.94902C2 4.73007 2 5.98714 2 8.5013" stroke="#212121"/>
    <path d="M8 5.16797V8.5013" stroke="#212121" stroke-width="1.5"/>
    </svg>
    </>
  )
}

export default BedIcons