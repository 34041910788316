// @ts-nocheck
import { useEffect, useState } from "react";
import ContainerMedia from "Components/ContainerMedia/ContainerMedia";
import { useParams, Navigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../Admin/Admin.css";
import useProperty from "hooks/useProperty";
import useCities from "hooks/useCities";
import useDevelopers from "hooks/useDevelopers";
import { updateProperty } from "services/propertyService";

const EditPropertyForm = () => {
  const { id } = useParams(); 
  const{ property }=useProperty(id)
  const { cities } = useCities();
  const { developers } = useDevelopers();
  const [formData, setFormData] = useState({
    description_ar: "",
    description_en: "",
    images: [],
    location: { lat: "", lng: "" },
    number_of_bathrooms: "",
    number_of_rooms: "",
    yearsOfInstallments: "",
    downPayment: "",
    price: "",
    property_name_ar: "",
    property_name_en: "",
    square_m: "",
    type: "",
    amenities: {
      Furnished: false,
      Garden: false,
      Gym: false,
      Parking: false,
      Pool: false,
      ready_to_move: false,
    },
    delivery_date: "",
    developer: "",
    property_status: "",
    city: "",
  });
  useEffect(() => {
    if (property) {
      // @ts-ignore
      setFormData({
        property_name_ar: property.title_ar ,
        property_name_en: property.title_en ,
        description_ar: property.text_ar ,
        description_en: property.text_en ,
        images: [],
        type: property.type,
        yearsOfInstallments: property.yearsOfInstallments,
        downPayment: property.downPayment,
        location: {
          lat: property.lat ,
          lng: property.lng 
        },
        number_of_bathrooms: property.bedNum ,
        number_of_rooms: property.bathNum ,
        price: property.price ,
        square_m: property.space ,
        delivery_date: property.deliveryDate, 
        property_status: property.status, 
        developer: property.developerId , 
        city: property.cityId , 
        amenities: {
          Furnished: property.furnished ,
          Garden: property.has_garden ,
          Gym: property.has_gym ,
          Parking: property.parking ,
          Pool: property.has_pool ,
          ready_to_move: property.isReadyToMove ,
        }
      });
    }
  }, [property]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setFormData({
        ...formData,
        amenities: {
          ...formData.amenities,
          [name]: checked
        }
      });
    } else if (name === "lat" || name === "lng") {
      setFormData({
        ...formData,
        location: {
          ...formData.location,
          [name]: value
        }
      });
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };
  
  const handlePropertyImagesChange = (e) => {
    setFormData({
      ...formData,
      images: e.target.value
    });
  };



  // FUNCTIONS TO HANDLE LOADER AND DISABLED STATE
  const [loaderButton, setLoaderButton] = useState(false);
  const [disabledValue, setDisabledValue] = useState(false);

  const addLoaderDisabled = () => {
    // ADD LOADER AND DISABLED AS TRUE
    setLoaderButton(true);
    setDisabledValue(true);
  };

  const removeDisabledLoader = () => {
    // ADD TO LOADER AND DISABLED FALSE TO STOP
    setDisabledValue(false);
    setLoaderButton(false);
  };

  const handleSubmit = async (e) => {
     e.preventDefault();
     let body = {
       title_en: formData.property_name_en,
       title_ar: formData.property_name_ar,
       type: formData.type,
       price: formData.price,
       lat: formData.location.lat,
       lng: formData.location.lng,
       status: formData.property_status,
       bedNum: formData.number_of_rooms,
       space: formData.square_m,
       bathNum: formData.number_of_bathrooms,
       isReadyToMove: formData.amenities.ready_to_move,
       deliveryDate: formData.delivery_date,
       text_en: formData.description_en,
       text_ar: formData.description_ar,
       cityId: formData.city ,
       developerId: formData.developer ,
       has_pool: formData.amenities.Pool,
       has_gym: formData.amenities.Gym,
       has_garden: formData.amenities.Garden,
       furnished: formData.amenities.Furnished,
       parking: formData.amenities.Parking,
       yearsOfInstallments: formData.yearsOfInstallments,
       downPayment: formData.downPayment
     }
    try {
      addLoaderDisabled();
      await updateProperty(property.id,body);
      toast.success("Property updated successfully");
      removeDisabledLoader();
    } catch (error) {
      console.log(error);
      toast.error("Error updating property");
      removeDisabledLoader();
    }
  };
  
  return (
    <div className="admin-dash all-padding">
    <ContainerMedia>
    <div className="buttons-top-admin d-flex justify-content-between gap-3 align-items-center flex-wrap-reverse mb-4">
          <div className="main-buttons-top d-flex align-items-center gap-3 flex-wrap">
            <Link to="/admin/propertyManagement" className="btn-main">
              View Properties
            </Link>
            <Link to="/admin/contactMessages" className="btn-main">
              Contact Messages
            </Link>
            <Link to="/admin/cityManagement" className="btn-main">
              Add City
            </Link>
            <Link to="/admin/developerManagement" className="btn-main">
              Add Developer
            </Link>
          </div>

        </div>
      <div className="mb-3">
        <h2 className="title-admin">Edit Property</h2>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="property_name_ar" className="form-label mb-2">
            Property Name (Arabic)
          </label>
          <input
            type="text"
            className="form-control"
            id="property_name_ar"
            name="property_name_ar"
            placeholder="Property Name (Arabic)"
            value={formData.property_name_ar}
            onChange={handleChange}
          />
        </div>
  
        <div className="mb-3">
          <label htmlFor="property_name_en" className="form-label mb-2">
            Property Name (English)
          </label>
          <input
            type="text"
            className="form-control"
            id="property_name_en"
            name="property_name_en"
            placeholder="Property Name (English)"
            value={formData.property_name_en}
            onChange={handleChange}
          />
        </div>

        <div className="mb-3">
            <label htmlFor="property_type" className="form-label mb-2">
              Property Type
            </label>
            <select
              className="form-select custom-select-arrow"
              id="property_type"
              name="type"
              value={formData.type}
              onChange={handleChange}
              style={{ paddingRight: "2rem", backgroundImage: "none" ,  appearance: "auto"}}
            >
              <option value="">Select Property Type</option>
              <option value="Apartment">Apartment</option>
              <option value="Chalet">Chalet</option>
              {/* <option value="Villa">Villa</option> */}
            </select>
          </div>
  
          <div className="mb-3">
          <label htmlFor="delivery_date" className="form-label mb-2">
            Delivery Date (in years)
          </label>
          <input
            type="number"
            onWheel={(e) => e.target.blur()}
            className="form-control"
            id="delivery_date"
            name="delivery_date"
            placeholder="Delivery Date (in years)"
            value={formData.delivery_date}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="city" className="form-label mb-2">
            City
          </label>
          <select
            className="form-select custom-select-arrow"
            id="city"
            name="city"
            value={formData.city}
            onChange={handleChange}
            style={{ paddingRight: "2rem", backgroundImage: "none" ,  appearance: "auto"}}
          >
            <option value="">Select City</option>
            {cities.map((city) => (
              <option key={city.id} value={city.id}>
                {city.name_en} / {city.name_ar}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-3">
          <label htmlFor="developer" className="form-label mb-2">
            Developer
          </label>
          <select
            className="form-select custom-select-arrow"
            id="developer"
            name="developer"
            value={formData.developer}
            onChange={handleChange}
            style={{ paddingRight: "2rem", backgroundImage: "none", appearance: "auto" }}
          >
            <option value="">Select Developer</option>
            {developers.map((developer) => (
              <option key={developer.id} value={developer.id}>
                {developer.name_en} / {developer.name_ar}
              </option>
            ))}
          </select>
        </div>

        <div className="mb-3">
              <label htmlFor="property_status" className="form-label mb-2">
                Property Status
              </label>
              <select
                
                className="form-select"
                id="property_status"
                name="property_status"
                value={formData.property_status}
                onChange={handleChange}
                style={{ paddingRight: "2rem", backgroundImage: "none" ,  appearance: "auto"}}
              >
                <option value="">Select Property Status</option>
                <option value="fully-finished">Fully-Finished</option>
                <option value="semi-finished">Semi-Finished</option>
                <option value="core and shell">Core and Shell</option>
              </select>
        </div>

        <div className="mb-3">
          <label htmlFor="description_ar" className="form-label mb-2">
            Description (Arabic)
          </label>
          <input
            type="text"
            className="form-control"
            id="description_ar"
            name="description_ar"
            placeholder="Description (Arabic)"
            value={formData.description_ar}
            onChange={handleChange}
          />
        </div>
  
        <div className="mb-3">
          <label htmlFor="description_en" className="form-label mb-2">
            Description (English)
          </label>
          <input
            type="text"
            className="form-control"
            id="description_en"
            name="description_en"
            placeholder="Description (English)"
            value={formData.description_en}
            onChange={handleChange}
          />
        </div>
  
        <div className="mb-3">
            <label htmlFor="location_lat" className="form-label mb-2">
              Location Latitude
            </label>
            <input
              type="text"
              className="form-control"
              id="location_lat"
              name="location_lat"
              placeholder="Latitude"
              value={formData.location.lat}
              onChange={handleChange}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="location_lng" className="form-label mb-2">
              Location Longitude
            </label>
            <input
              type="text"
              className="form-control"
              id="location_lng"
              name="location_lng"
              placeholder="Longitude"
              value={formData.location.lng}
              onChange={handleChange}
            />
          </div>

          {/* <div className="mb-3">
            <label htmlFor="property_images" className="form-label mb-2">
              Property Images
            </label>
            <input
              type="file"
              className="form-control"
              id="property_images"
              name="property_images"
              multiple
              onChange={handlePropertyImagesChange}
            />
          </div>
   */}
       
        <div className="mb-3">
          <label htmlFor="number_of_rooms" className="form-label mb-2">
            Number of Rooms
          </label>
          <input
            type="number"
            onWheel={(e) => e.target.blur()}
            className="form-control"
            id="number_of_rooms"
            name="number_of_rooms"
            placeholder="Number of Rooms"
            value={formData.number_of_rooms}
            onChange={handleChange}
          />
        </div>
    
        <div className="mb-3">
          <label htmlFor="number_of_bathrooms" className="form-label mb-2">
            Number of Bathrooms
          </label>
          <input
            type="number"
            onWheel={(e) => e.target.blur()}
            className="form-control"
            id="number_of_bathrooms"
            name="number_of_bathrooms"
            placeholder="Number of Bathrooms"
            value={formData.number_of_bathrooms}
            onChange={handleChange}
          />
        </div>
  
        
        <div className="mb-3">
          <label htmlFor="square_m" className="form-label mb-2">
            Square Meters
          </label>
          <input
            type="number"
            onWheel={(e) => e.target.blur()}
            className="form-control"
            id="square_m"
            name="square_m"
            placeholder="Square Meters"
            value={formData.square_m}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="price" className="form-label mb-2">
            Price
          </label>
          <input
            type="number"
            onWheel={(e) => e.target.blur()}
            className="form-control"
            id="price"
            name="price"
            placeholder="Price"
            value={formData.price}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
            <label htmlFor="yearsOfInstallments" className="form-label mb-2">
               Years of Installment
            </label>
            <input
              type="number"
              onWheel={(e) => e.target.blur()}
              className="form-control"
              id="yearsOfInstallments"
              name="yearsOfInstallments"
              placeholder="Years of Installment"
              value={formData.yearsOfInstallments}
              onChange={handleChange}
            />
        </div>
        <div className="mb-3">
          <label htmlFor="downPayment" className="form-label mb-2">
            Down Payment
          </label>
          <input
            type="number"
            onWheel={(e) => e.target.blur()}
            className="form-control"
            id="downPayment"
            name="downPayment"
            placeholder=" Down Payment"
            value={formData.downPayment}
            onChange={handleChange}
          />
        </div>
       
        <div className="all-check-inputs-form me-3 d-flex alin-items-center flex-wrap gap-3">
            <div className="mb-3">
              <div className="form-check">
                <input
                  type="checkbox"
                  id="ready_to_move"
                  name="ready_to_move"
                  checked={formData.amenities.ready_to_move}
                  onChange={handleChange}
                  className="form-check-input"
                />
                <label htmlFor="ready_to_move">Ready to Move</label>
              </div>
            </div>
          </div>
       
        <div className="mb-3">
          <label htmlFor="amenities" className="form-label mb-2">
            Amenities
          </label>
          <div className="all-check-inputs-form me-3 d-flex align-items-center flex-wrap gap-3">
            <div className="form-check">
              <input
                type="checkbox"
                id="Furnished"
                name="Furnished"
                checked={formData.amenities.Furnished}
                onChange={handleChange}
                className="form-check-input"
              />
              <label htmlFor="Furnished">Furnished</label>
            </div>
            <div className="form-check">
              <input
                type="checkbox"
                id="Garden"
                name="Garden"
                checked={formData.amenities.Garden}
                onChange={handleChange}
                className="form-check-input"
              />
              <label htmlFor="Garden">Garden</label>
            </div>
            <div className="form-check">
              <input
                type="checkbox"
                id="Gym"
                name="Gym"
                checked={formData.amenities.Gym}
                onChange={handleChange}
                className="form-check-input"
              />
              <label htmlFor="Gym">Gym</label>
            </div>
            <div className="form-check">
              <input
                type="checkbox"
                id="Parking"
                name="Parking"
                checked={formData.amenities.Parking}
                onChange={handleChange}
                className="form-check-input"
              />
              <label htmlFor="Parking">Parking</label>
            </div>
            <div className="form-check">
              <input
                type="checkbox"
                id="Pool"
                name="Pool"
                checked={formData.amenities.Pool}
                onChange={handleChange}
                className="form-check-input"
              />
              <label htmlFor="Pool">Pool</label>
            </div>
          </div>
        </div>
          <button
            // @ts-ignore
            disabled={disabledValue ? "disabled" : ""}
            type="submit"
            className={`btn-main  mt-3 ${disabledValue ? "bttDisabled" : ""}`}
          >
            {loaderButton ? (
              <div className="spinner-border text-light" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              "Update Property"
            )}
          </button>
        </form>
        {/* ============ START INPUT FORM ============= */}
      </ContainerMedia>
    </div>
  );
};

export default EditPropertyForm;
