import { useEffect } from "react";
import { useLocation } from "react-router-dom"; 

const useDetailsPageClass = () => {
  const location = useLocation();

  useEffect(() => {
    const handleRouteChange = () => {
      const { pathname } = location;
      if (pathname.includes("/detailsProperties/")) {
        document.body.classList.add("details-page-body");
      } else {
        document.body.classList.remove("details-page-body");
      }
    };
    handleRouteChange();
    return () => {
      document.body.classList.remove("details-page-body");
    };
  }, [location]);
};

export default useDetailsPageClass;
