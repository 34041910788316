import Bathtub from "assets/images/products/icons/Bathtub";
import BedIcons from "assets/images/products/icons/BedIcons";
import SquareIcon from "assets/images/products/icons/SquareIcon";
import "./RightContentDetails.css";
import PhoneIcon from "assets/images/IconsDetails/PhoneIcon";
import ModalDetailsContent from "../../ModalDetailsContent/ModalDetailsContent";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import NumberFormatter from "Shared/NumberFormatter/NumberFormatter";
import CheckReadyIcon from "assets/images/iconsSvg/CheckReadyIcon";

const RightContentDetails = ({property}) => {
  const {t, i18n} =useTranslation()
  const [showModalContent, setShowModalContent] = useState(false);
  const buttonShowModal = () => {
    setShowModalContent(true);
  };
  const buttonhideModal = () => {
    setShowModalContent(false);
  };
  function calculatePricePerUnitSpace(price, space) {
    const pricePerUnitSpace = price / space;
    return pricePerUnitSpace.toFixed(2);
  }
  return (
    <>
      <ModalDetailsContent
        showModalDetails={showModalContent}
        hideModalDetails={buttonhideModal}
         property={property}
      />

      <div className="right-content-details-pro">
        {/* ================ START INFO DETAILS ================ */}
        <div className="info-details">
        <h2 className="title nexaBold-font">{i18n.language === "ar" ? property.title_ar : property.title_en}</h2>
        <p className="text">{i18n.language === "ar"
                ? property.city.name_ar
                : property.city.name_en}</p>
       
       {property.isReadyToMove &&
          <div className="badge-info-text d-flex align-items-center  gap-2">
            <CheckReadyIcon /> {t("Ready to move")}
          </div>}
        {!property.isReadyToMove &&
          <div className="delivery-date d-flex align-items-center gap-2">
           { t("Delivery date")} : <span className="date-info">{property.deliveryDate} </span>
          </div>}
          
          <div className="down-payment d-flex align-items-center gap-2">
            <span>{property.downPayment}%</span>  { t("Down Payment")} / <span>{property.yearsOfInstallments}</span> { t("Years")} 
          </div>
          <ul className="list-items-info p-0 m-0 flex-wrap  d-flex align-items-center gap-3">
            <li className="list-one-li">
              <div className="btn-main btn-transparent-2">
                <BedIcons /> {property.bedNum}
              </div>
            </li>
            <li className="list-one-li">
              <div className="btn-main btn-transparent-2">
                {" "}
                <Bathtub /> {property.bathNum}
              </div>
            </li>
            <li className="list-one-li">
              <div className="btn-main btn-transparent-2">
                {" "}
                <SquareIcon /> {property.space} {t("squareMeters")}
              </div>
            </li>
          </ul>
          <div className="price-content price-end d-flex align-items-center gap-1">
            <sub>egp</sub>{" "}
            <span className="price-num nexaBold-font">
              <NumberFormatter number={property.price} />
            </span>
          </div>
         
          <div className="buttons-check d-flex flex-column gap-2 w-100">
            <button
              onClick={buttonShowModal}
              className="btn-main btn-height w-100"
            >
              {t("Express interest")}
            </button>
            <a href="tel:19484" className="btn-main btn-green w-100 btn-height">
              <PhoneIcon /> {t("Call Us")}
            </a>
          </div>
        </div>
        {/* ================ END INFO DETAILS ================ */}
      </div>
    </>
  );
};

export default RightContentDetails;
