import "./CardInfoHowWork.css";
const CardInfoHowWork = ({ numCard, titleCard, textCard }) => {
  return (
    <div className="card-info-how-work h-100">
      {/* ============ START NUM INFO TOP ========== */}
      <div className="num-info-top nexaBold-font">{numCard}</div>
      {/* ============ END NUM INFO TOP ========== */}
      {/* ============ START CONTENT INFO CARD =========== */}
      <div className="content-info-card">
        <h2 className="title nexaBold-font">{titleCard}</h2>
        <p className="text">{textCard}</p>
      </div>
      {/* ============ END CONTENT INFO CARD =========== */}
    </div>
  );
};

export default CardInfoHowWork;
