import EmailIcon from "assets/images/iconsSvg/EmailIcon";
import Phone from "assets/images/iconsSvg/Phone";
import "./FormContactPage.css"
import { useTranslation } from "react-i18next";

const InfoContactPage = () => {
  // FOR TRANSLATION AR && EN
  const {t}=useTranslation()
  return (
    <div className="info-contact-page">
      <h2 className="title nexaBold-font">{t("titlePageContact")}</h2>
      <p className="text text-500">
      {t("textPageContact")}
      </p>
      <div className="info-contact-details d-flex flex-column gap-3">
        <a href="tel:19484" className="link-contact">
          <Phone /> 19484
        </a>
        <a href="mailto:info@aqar-egypt.com" className="link-contact">
          <EmailIcon /> info@aqar-egypt.com
        </a>
      </div>
    </div>
  );
};

export default InfoContactPage;
