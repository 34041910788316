import { useState, useEffect } from "react";
import { fetchDevelopers } from "../services/developerService";

const useDevelopers = () => {
  const [developers, setDevelopers] = useState([]);
 

  useEffect(() => {
    const getDevelopers = async () => {
      try {
        const data = await fetchDevelopers();
        setDevelopers(data);
      } catch (error) {
        console.log(error);
      }
    };

    getDevelopers();
  }, []);

  return { developers };
};

export default useDevelopers;
