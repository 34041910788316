import { useState } from 'react';

export const usePhoneNumber = () => {
  const [phone, setPhone] = useState('');

  const handlePhoneChange = (value, setFieldValue) => {
    setPhone(value);
    setFieldValue('phone', value);
  };

  return { phone, handlePhoneChange, setPhone };
};
