import ContainerMedia from "Components/ContainerMedia/ContainerMedia";
import imageBanner1 from "../../../../assets/images/main/bannerhome.png";
import "./BannerHome.css";
import ArrowIcon from "assets/images/iconsSvg/ArrowIcon";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const BannerHome = () => {
  // TRANSLATION AR && EN
  const { t } = useTranslation();

  return (
    <div className="main-banner-home padding-top-50">
      <div className="banner-main-area">
        {/* ========= START BANNER ONE ======== */}
        <div
          className="banner-one section-padding bg-image"
          style={{ backgroundImage: `url(${imageBanner1})` }}
        >
          {/* ======== START INFO BANNER ========= */}
          <div className="info-banner position-relative z-1">
            {/* ======= START CONTAINER =========== */}
            <ContainerMedia>
              {/* ========= START CONTENT SLIDE ========== */}
              <div className="content-slide">
                <h2 className="title nexaBold-font">{t("newlaunches")}</h2>

                <div className="buttons-slide d-flex flex-wrap align-items-center justify-content-center gap-3">
                  <Link to="/properties" className="btn-main btn-transparent">
                    {t("explore")} <ArrowIcon />
                  </Link>
                </div>
              </div>
              {/* ========= END CONTENT SLIDE ============ */}
            </ContainerMedia>
            {/* ======== END CONTAINER =========== */}
          </div>
          {/* ======== END INFO BANNER ========== */}
        </div>
        {/* ======== END BANNER ONE ========= */}
      </div>
    </div>
  );
};

export default BannerHome;
