import React from 'react';
import ContainerMedia from 'Components/ContainerMedia/ContainerMedia';
import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import "./ImagesProperties.css";

const ImagesProperties = ({ images }) => {
  const onInit = () => {
    console.log("LightGallery initialized");
  };

  const defaultImage = images.find(image => image.isDefault);
  const imagesToShow = images.filter(image => (!image.isDefault))
  return (
    <div className="all-images-properties padding-top-30">
      <ContainerMedia>
        <div className="row g-3">
          <div className="col-12 col-md-8">
            <div  className="large-image-bg">
              <img
                src={`${process.env.REACT_APP_IMAGE_URL}/${defaultImage?.url}`}
              />
            </div>
          </div>
          <div className="col-12 col-md-4">
            <LightGallery
              onInit={onInit}
              speed={500}
              plugins={[lgThumbnail, lgZoom]}
              elementClassNames="images-slide-show"
            >
              {imagesToShow.map((item) => (
                <a className="image-src" href={`${process.env.REACT_APP_IMAGE_URL}/${item?.url}`} key={item.id}>
                  <img alt={`Image-${item.id}`} src={`${process.env.REACT_APP_IMAGE_URL}/${item?.url}`} />
                </a>
              ))}
            </LightGallery>
          </div>
        </div>
      </ContainerMedia>
    </div>
  );
};

export default ImagesProperties;
