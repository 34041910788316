import CustomModal from "Components/CustomModal/CustomModal";
import RightModalContent from "./RightModalContent";
import LeftModalContent from "./LeftModalContent";
import "./ModalDetailsContent.css";
import ModalSuccess from "Shared/ModalSuccess/ModalSuccess";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const ModalDetailsContent = ({ showModalDetails, hideModalDetails ,property}) => {
  // FOR TRANSLATION AR && EN
  const { t } = useTranslation();
  // SHOW MODAL SUCCESS SEND
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const buttonShowModalSuccess = () => { // BUTTON TO SHOW MODALSUCCESS ADD TRUE
    setShowModalSuccess(true);
  }; 
  const buttonhideModalSuccess = () => { // BUTTON TO HIDE MODAL SUCCESS ADD FLASE
    setShowModalSuccess(false);
  };
  return (
    <>
      <ModalSuccess
        showModalSuccess={showModalSuccess}
        hideModalSuccess={buttonhideModalSuccess}
        title={t("message sent")}
        text={t(
          "thank you for expressing your interest in Mivida property Our team will review your request and get in touch with you soon"
        )}
      />
      <CustomModal
        show={showModalDetails}
        onHide={hideModalDetails}
        title={""}
        newClass={"modal-width-content"}
      >
        {/* ================== START ALL CONTENT MODAL ============== */}
        <div className="all-content-modal">
          {/* ================ START ROW =============== */}
          <div className="row g-3">
            {/* =============== START COL ============== */}
            <div className="col-12 col-md-6">
              <LeftModalContent      
                  property={property}
               />
            </div>
            {/* =============== END COL ============== */}
            {/* =============== START COL ============== */}
            <div className="col-12 col-md-6">
              <RightModalContent
                hideModalDetails={hideModalDetails}
                buttonShowModalSuccess={buttonShowModalSuccess}
              />
            </div>
            {/* =============== END COL ============== */}
          </div>
          {/* ================ END ROW =============== */}
        </div>
        {/* ================== END ALL CONTENT MODAL ============== */}
      </CustomModal>
    </>
  );
};

export default ModalDetailsContent;
