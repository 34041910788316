import ArrowIconTop from "assets/images/iconsSvg/ArrowIconTop";
import DropMenuContent from "../../../../Shared/DropMenuContent/DropMenuContent";
import "./HeaderTopContent.css";
import { useTranslation } from "react-i18next";

const HeaderTopContent = ({handleOrderByChange,count, showMap, handleCheckboxClick }) => {
  const { i18n } = useTranslation();
  const checkboxItems = [
    {
      id: "flexCheckDefault3",
      label: i18n.language === "ar" ? "اقل سعر" : "Minimum price",
      attr: "price",
      type: "asc"
    },
    {
      id: "flexCheckDefault4",
      label: i18n.language === "ar" ? "اعلي سعر" : "Maximum Price",
      attr: "price",
      type: "desc"
    },
    {
      id: "flexCheckDefault5",
      label: i18n.language === "ar" ? "اقل مساحة" : "Minimum Sq.m",
      attr: "space",
      type: "asc"

    },
    {
      id: "flexCheckDefault6",
      label: i18n.language === "ar" ? "اعلي مساحة" : "Maximum Sq.m",
      attr: "space",
      type: "desc"
    }
  ];

  return (
    <>
      
      <div className="header-content-top d-flex justify-content-between align-items-center gap-3 flex-wrap">
        <h2 className="results-product-count nexaBold-font">
          {i18n.language === "ar"
            ? `اكثر من ${count} نتيجة`
            : `Over ${count} Results`}
        </h2>
        <div className="right-content-top d-flex align-items-center gap-3">
          <DropMenuContent
          
           handleChange={handleOrderByChange}
           newClassButton={"button-drop"}
           textButton={
              <>
                {i18n.language === "ar" ? "رتب" : "Sort"} <ArrowIconTop />
              </>
            }
            checkboxItems={checkboxItems}
          />

          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckChecked"
              defaultChecked={showMap}
              onClick={handleCheckboxClick}
            />
            <label
              className="form-check-label nexaBold-font text-500"
              htmlFor="flexSwitchCheckChecked"
            >
              {i18n.language === "ar" ? "الخريطة" : "View map"}
            </label>
          </div>
        </div>
      </div>
      {/* ================ END HEADER CONTENT TOP ============= */}
    </>
  );
};

export default HeaderTopContent;
