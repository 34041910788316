import routers from "./Routes/Routers";
import "./App.css";
import { HelmetProvider } from "react-helmet-async";
import { RouterProvider } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.rtl.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "@fortawesome/react-fontawesome";
import "@fortawesome/fontawesome-free/css/all.min.css";
import ToastContainerApp from "Components/ToastContainerApp/ToastContainerApp";
import ButtonScroll from "Components/ButtonScroll/ButtonScroll";
import WindowLoader from "Components/WindowLoader/WindowLoader";
import "./i18n";
import "./Rtl/Rtl.css";
import { AuthProvider } from "Context/AuthProvider";

function App() {
  return (
    <AuthProvider>
      <div className="App">
        <WindowLoader />
        <ToastContainerApp />
        <ButtonScroll />
        <HelmetProvider>
          <RouterProvider router={routers} />
        </HelmetProvider>
      </div>
    </AuthProvider>
  );
}

export default App;
