import React from 'react'

const IconSuccess = () => {
  return (
    <><svg width="62" height="46" viewBox="0 0 62 46" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M57.9723 0C54.4231 0 44.5498 2.71507 37.0875 7.65062C33.9959 9.75461 31.2197 12.241 28.8219 15.174C25.8824 18.8194 21.9255 23.5015 21.2406 25.5472C16.8695 21.6763 11.5856 19.1489 5.79137 18.6808C3.9936 18.5508 -0.0124343 19.3312 0.554442 20.0194C6.24722 26.9371 23.269 45.9037 23.269 45.9037C23.3234 45.9587 23.5594 46.12 23.8369 45.8401C32.8333 33.3407 40.319 21.7232 50.2111 12.3052C57.0365 5.93982 58.8471 5.61891 60.559 3.88922C62.1977 1.93248 61.9116 0.265358 57.9723 0.000539307V0Z" fill="#00207E"/>
    </svg>
    </>
  )
}

export default IconSuccess