import React from "react";
import * as XLSX from "xlsx";

const ExportToExcel = ({ messages }) => {
  // EXPORT MESSAGE CONTACT  TO EXCEL
  const handleExportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(messages);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Contact Messages");
    XLSX.writeFile(workbook, "ContactMessages.xlsx");
  };

  return (
    <button onClick={handleExportToExcel} className="btn-main btn-green-execl">
      Export to Excel
    </button>
  );
};

export default ExportToExcel;
