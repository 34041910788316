import React from "react";

const ArrowIconTop = () => {
  return (
    <>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M8.70879 5.59998L5.60876 2.5L2.50879 5.59998" fill="#212121" />
        <path
          d="M8.70879 5.59998L5.60876 2.5L2.50879 5.59998"
          stroke="#212121"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M5.6084 17.5L5.6084 2.5"
          stroke="#212121"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11.292 14.4L14.392 17.5L17.492 14.4"
          stroke="#212121"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14.3926 2.5L14.3926 17.5"
          stroke="#212121"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};

export default ArrowIconTop;
