import "./Pagination.css";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeftLong,
  faArrowRightLong,

} from "@fortawesome/free-solid-svg-icons";

const PaginationPage = ({ count, rowsPerPage, page, handleChangePage }) => {
  
  return (
    <div className="all-pagination d-flex justify-content-center align-items-center mt-5">
      <ReactPaginate
        breakLabel="..."
        nextLabel={<FontAwesomeIcon icon={faArrowRightLong} />}
        onPageChange={handleChangePage}
        pageRangeDisplayed={2}
        marginPagesDisplayed={2}
        initialPage={page-1}
        pageCount={Math.ceil(count/rowsPerPage)}
        previousLabel={<FontAwesomeIcon icon={faArrowLeftLong} />}
        renderOnZeroPageCount={null}
        containerClassName="pagination d-flex align-items-center gap-2"
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        nextClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextLinkClassName={"page-link"}
        breakLinkClassName={"page-link"}
        activeClassName={"active-1"}
      />
  
    </div>
  );
};

export default PaginationPage;
