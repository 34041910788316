import React from "react";

const DownloadIcon = () => {
  return (
    <>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.0005 7.29174C9.65885 7.29174 9.37552 7.00841 9.37552 6.66674V3.17507L8.77552 3.77507C8.53385 4.01674 8.13385 4.01674 7.89219 3.77507C7.65052 3.53341 7.65052 3.13341 7.89219 2.89174L9.55885 1.22507C9.73385 1.05007 10.0089 0.991739 10.2422 1.09174C10.4755 1.18341 10.6255 1.41674 10.6255 1.66674V6.66674C10.6255 7.00841 10.3422 7.29174 10.0005 7.29174Z"
          fill="#212121"
        />
        <path
          d="M11.6685 3.95755C11.5102 3.95755 11.3518 3.89922 11.2268 3.77422L9.56016 2.10755C9.31849 1.86589 9.31849 1.46589 9.56016 1.22422C9.80182 0.982552 10.2018 0.982552 10.4435 1.22422L12.1102 2.89089C12.3518 3.13255 12.3518 3.53255 12.1102 3.77422C11.9852 3.89922 11.8268 3.95755 11.6685 3.95755Z"
          fill="#212121"
        />
        <path
          d="M13.3333 18.9583H6.66667C1.875 18.9583 1.875 16.4167 1.875 14.1667V13.3333C1.875 11.475 1.875 9.375 5.83333 9.375C6.825 9.375 7.19167 9.61667 7.70833 10C7.73333 10.025 7.76667 10.0417 7.79167 10.075L8.64167 10.975C9.35833 11.7333 10.6583 11.7333 11.375 10.975L12.225 10.075C12.25 10.05 12.275 10.025 12.3083 10C12.825 9.60833 13.1917 9.375 14.1833 9.375C18.1417 9.375 18.1417 11.475 18.1417 13.3333V14.1667C18.125 17.35 16.5167 18.9583 13.3333 18.9583ZM5.83333 10.625C3.125 10.625 3.125 11.475 3.125 13.3333V14.1667C3.125 16.45 3.125 17.7083 6.66667 17.7083H13.3333C15.8167 17.7083 16.875 16.65 16.875 14.1667V13.3333C16.875 11.475 16.875 10.625 14.1667 10.625C13.5667 10.625 13.4417 10.7 13.0833 10.9667L12.275 11.825C11.675 12.4583 10.8667 12.8083 10 12.8083C9.13333 12.8083 8.325 12.4583 7.725 11.825L6.91667 10.9667C6.55833 10.7 6.43333 10.625 5.83333 10.625Z"
          fill="#212121"
        />
        <path
          d="M4.16797 10.6268C3.8263 10.6268 3.54297 10.3435 3.54297 10.0018V8.33514C3.54297 6.71847 3.54297 4.71014 6.60964 4.41848C6.94297 4.37681 7.25964 4.63514 7.29297 4.98514C7.3263 5.32681 7.0763 5.63514 6.7263 5.66848C4.79297 5.84348 4.79297 6.62681 4.79297 8.33514L4.79297 10.0018C4.79297 10.3435 4.50964 10.6268 4.16797 10.6268Z"
          fill="#212121"
        />
        <path
          d="M15.8345 10.6268C15.4928 10.6268 15.2095 10.3435 15.2095 10.0018V8.33514C15.2095 6.62681 15.2095 5.84348 13.2762 5.66014C12.9345 5.62681 12.6845 5.31848 12.7178 4.97681C12.7512 4.63514 13.0512 4.37681 13.4012 4.41848C16.4678 4.71014 16.4678 6.71848 16.4678 8.33514V10.0018C16.4595 10.3435 16.1762 10.6268 15.8345 10.6268Z"
          fill="#212121"
        />
      </svg>
    </>
  );
};

export default DownloadIcon;
