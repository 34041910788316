import About from "Pages/About/About";
import Layout from "../Components/Layout/Layout";
import Home from "../Pages/Home/Home";
import NotFound from "../Pages/NotFound/NotFound";
import { Outlet, createBrowserRouter } from "react-router-dom";
import Faq from "Pages/Faq/Faq";
import ContactUsPage from "Pages/ContactUsPage/ContactUsPage";
import Properties from "Pages/Properties/Properties";
import DetailsProperties from "Pages/DetailsProperties/DetailsProperties";
import Results from "Pages/Results/Results";
import Admin from "Pages/Admin/Admin";
import PropertyManagement from "Pages/Admin/PropertyManagement";
// @ts-ignore
import CityManagement from "Pages/Admin/cityManagement";
import DeveloperManagement from "Pages/Admin/developerManagement";
import EditPropertyForm from "Pages/Admin/EditPropertyForm";
import PrivateRoute from "Pages/Admin/PrivateRoute";
import Login from "Pages/Admin/Login";
import ContactMessages from "../Pages/Admin/MessagesTable/ContactMessages";

let routers = createBrowserRouter([
  {
    path: "",
    element: <Layout />,
    children: [
      {
        path: "",
        element: (
          <>
            <Outlet />
          </>
        ),
        children: [
          {
            index: true,
            element: <Home />
          },
          {
            path: "detailsProperties/:id",
            element: <DetailsProperties />
          }
        ]
      },
      { path: "about", element: <About /> },
      { path: "properties", element: <Properties /> },
      { path: "faq", element: <Faq /> },
      { path: "contactUsPage", element: <ContactUsPage /> },
      { path: "results", element: <Results /> },
      { path: "login", element: <Login /> },
      {
        path: "admin",
        element: <PrivateRoute />,
        children: [
          { path: "", element: <Admin /> },
          { path: "propertyManagement", element: <PropertyManagement /> },
          { path: "CityManagement", element: <CityManagement /> },
          { path: "DeveloperManagement", element: <DeveloperManagement /> },
          { path: "contactMessages", element: <ContactMessages /> },
          { path: "edit/:id", element: <EditPropertyForm /> }
        ]
      }
    ]
  },
  { path: "*", element: <NotFound /> }
]);

export default routers;
