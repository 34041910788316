import { useState } from "react";
import { useSearchParams } from "react-router-dom";

const usePaginationParams = () => {
    const [searchParams] = useSearchParams();
    const [page, setPage] = useState(
        searchParams.get("page") ? parseInt(searchParams.get("page")) : 1
      );
      const [rowsPerPage, setRowsPerPage] = useState(
        searchParams.get("rowsPerPage")
          ? parseInt(searchParams.get("rowsPerPage"))
          : 8
      );

      const handleChangePage = ({selected}) => {
        setPage(selected+1);
      };
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
      };

    return { page, handleChangePage ,rowsPerPage,handleChangeRowsPerPage};
};

export default usePaginationParams;
