import React from 'react'

const PhoneIcon = () => {
  return (
    <><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.3077 15.2763C18.3077 15.5763 18.241 15.8846 18.0993 16.1846C17.9577 16.4846 17.7744 16.768 17.5327 17.0346C17.1244 17.4846 16.6743 17.8096 16.166 18.018C15.666 18.2263 15.1244 18.3346 14.541 18.3346C13.691 18.3346 12.7827 18.1346 11.8243 17.7263C10.866 17.318 9.90768 16.768 8.95768 16.0763C7.99935 15.3763 7.09102 14.6013 6.22435 13.743C5.36602 12.8763 4.59102 11.968 3.89935 11.018C3.21602 10.068 2.66602 9.11797 2.26602 8.1763C1.86602 7.2263 1.66602 6.31797 1.66602 5.4513C1.66602 4.88464 1.76602 4.34297 1.96602 3.84297C2.16602 3.33464 2.48268 2.86797 2.92435 2.4513C3.45768 1.9263 4.04102 1.66797 4.65768 1.66797C4.89102 1.66797 5.12435 1.71797 5.33268 1.81797C5.54935 1.91797 5.74102 2.06797 5.89102 2.28464L7.82435 5.00964C7.97435 5.21797 8.08268 5.40964 8.15768 5.59297C8.23268 5.76797 8.27435 5.94297 8.27435 6.1013C8.27435 6.3013 8.21602 6.5013 8.09935 6.69297C7.99102 6.88464 7.83268 7.08464 7.63268 7.28464L6.99935 7.94297C6.90768 8.03464 6.86602 8.14297 6.86602 8.2763C6.86602 8.34297 6.87435 8.4013 6.89102 8.46797C6.91602 8.53464 6.94102 8.58464 6.95768 8.63464C7.10768 8.90964 7.36602 9.26797 7.73268 9.7013C8.10768 10.1346 8.50768 10.5763 8.94102 11.018C9.39102 11.4596 9.82435 11.868 10.266 12.243C10.6993 12.6096 11.0577 12.8596 11.341 13.0096C11.3827 13.0263 11.4327 13.0513 11.491 13.0763C11.5577 13.1013 11.6243 13.1096 11.6993 13.1096C11.841 13.1096 11.9493 13.0596 12.041 12.968L12.6743 12.343C12.8827 12.1346 13.0827 11.9763 13.2743 11.8763C13.466 11.7596 13.6577 11.7013 13.866 11.7013C14.0243 11.7013 14.191 11.7346 14.3743 11.8096C14.5577 11.8846 14.7494 11.993 14.9577 12.1346L17.716 14.093C17.9327 14.243 18.0827 14.418 18.1743 14.6263C18.2577 14.8346 18.3077 15.043 18.3077 15.2763Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10"/>
    <path d="M15.4167 7.5026C15.4167 7.0026 15.025 6.23594 14.4417 5.61094C13.9083 5.03594 13.2 4.58594 12.5 4.58594" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M18.3333 7.5013C18.3333 4.2763 15.725 1.66797 12.5 1.66797" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    </>
  )
}

export default PhoneIcon