import {
  faFacebookF,
  faInstagram,
  faTwitter,
  faYoutube
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LogoFooter from "Components/LogoInfo/LogoFooter";
const FACEBOOK_URL="https://www.facebook.com/profile.php?id=61559819624872"
const INSTAGRAM_urL=" https://www.instagram.com/aqar.egypt.official/"

const LeftContent = () => {
  return (
    <div className="left-content-footer">
      <div className="logo-footer">
        {" "}
        <LogoFooter />
      </div>
      {/* ============= START LIST SOCIAL ICONS ============= */}
      <div className="social-icons-info d-flex align-items-center gap-3 mt-4">
        <a
          className="icon-social-one"
          href={FACEBOOK_URL}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faFacebookF} />
        </a>
        <a
          className="icon-social-one"
          href={INSTAGRAM_urL}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faInstagram} />
        </a>
        {/* <a
          className="icon-social-one"
          href="https://www.twitter.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faTwitter} />
        </a> */}
        {/* <a
          className="icon-social-one"
          href="https://www.youtube.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faYoutube} />
        </a> */}
      </div>
      {/* ============= END LIST SOCIAL ICONS ============= */}
    </div>
  );
};

export default LeftContent;
