import React, { useState } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import TextAreaInput from "Components/Forms/TextArea";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "../ContactUs.css";
import ModalSuccess from "Shared/ModalSuccess/ModalSuccess";
import InputField from "Components/Forms/InputField";
import { useTranslation } from "react-i18next";
import { database } from "../../../Firebase/FirebaseConfig";
import { usePhoneNumber } from "./ComponentForm/usePhoneNumber";
import { getValidationSchema } from "./ComponentForm/getValidationSchema";
import { handleFormSubmit } from "./ComponentForm/handleFormSubmit";
import { contactUs } from "../../../services/contactService";

const FormContact = ({ titleTop }) => {
  const { t } = useTranslation();
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  // GET PHONE AND FUNCTION PHONE CHNAGE AND SETPHONE
  const { phone, handlePhoneChange, setPhone } = usePhoneNumber();
  // VALITAION
  const validationSchema = getValidationSchema(t);

  const initialValues = {
    name: "",
    phone: "",
    message: ""
  };

  const buttonShowModalSuccess = () => {
    // ADD SHOW TRUE FOR MODAL SUCCESS IF DATA SEND SUCCESS
    setShowModalSuccess(true);
  };
  // HIDE MODLAD SUCCESS
  const buttonhideModalSuccess = () => {
    setShowModalSuccess(false);
  };

  return (
    <>
      <ModalSuccess
        showModalSuccess={showModalSuccess}
        hideModalSuccess={buttonhideModalSuccess}
        title={t("modalMessageTtitle")}
        text={t("modalTextMessage")}
      />
      <div className="form-contact-content form-style form-style--1">
        {/* =========== START TOP HEADER INFO ==============  */}
        <div className="top-header-form-info">
          <h2 className="title nexaBold-font">{titleTop}</h2>
          <p className="text text-500">{t("headerFormText")}</p>
        </div>
        {/* =========== END TOP HEADER INFO ==============  */}
        {/* =========== START ALL FORM CONTACT =========== */}
        <div className="all-form-contact">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, actions) =>
              handleFormSubmit(
              {values,
              resetForm: actions.resetForm,
              buttonShowModalSuccess,
              hideModalDetails: setPhone,
              propertyId: ""}
              )
            }
          >
            {({ setFieldValue }) => (
              <Form>
                <InputField
                  label={t("nameLabelContact")}
                  name="name"
                  type="text"
                  placeholder=""
                  success
                />
                <div className="input-phone-number">
                  <label htmlFor="phone" className="form-label mb-1 d-none" />
                  <PhoneInput
                    country={"eg"}
                    value={phone}
                    onChange={(value) =>
                      handlePhoneChange(value, setFieldValue)
                    }
                    containerClass="custom-container w-100"
                    inputClass="form-control w-100"
                  />
                  <ErrorMessage
                    name="phone"
                    component="div"
                    className="error"
                  />
                </div>
                <TextAreaInput
                  label={t("messageLabelContact")}
                  name="message"
                  type="text"
                  placeholder=""
                  success
                />
                <button
                  type="submit"
                  className="btn-main btn-submit btn-height mt-3"
                >
                  {t("buttonexpressinterest")}
                </button>
              </Form>
            )}
          </Formik>
        </div>
        {/* =========== END ALL FORM CONTACT =========== */}
      </div>
    </>
  );
};

export default FormContact;
