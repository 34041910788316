import LocationIcon from 'assets/images/iconsSvg/LocationIcon';
import SeacrchIcon from 'assets/images/iconsSvg/SeacrchIcon';
import React, { useState } from 'react';

const CitySearch = ({ listings, onCitySelect,i18n }) => {
  const [searchTerm, setSearchTerm] = useState('');

  // const handleChange = (e) => {
  //   setSearchTerm(e.target.value);
  //   listings.filter((item)=> i18n.language === "ar" ?item.name_ar.contains(e.target.value):item.name_en.contains(e.target.value))
  // };

  const handleCityClick = (city) => {
    setSearchTerm( i18n.language === "ar" ?city.name_ar:city.name_en);
    onCitySelect(city);
  };
  

  return (
    <>
     <div className="search-input-filter">
     <input
     type="text"
     className='form-control'
     placeholder="Search by city"
     value={searchTerm}
     disabled={true}
    //  onChange={handleChange}
   />
        <div className="icon-search-filter-1">
        <SeacrchIcon />
        </div>
     </div>
      <ul className='list-city-content'>
        {listings?.map((listing, index) => (
          <li className='list-one-search' key={index} onClick={() => handleCityClick(listing)}>
          <LocationIcon />  <span> {i18n.language === "ar" ?listing.name_ar:listing.name_en}</span>
          </li>
        ))}
      </ul>
    </>
  );
};

export default CitySearch;
