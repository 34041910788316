import ContainerMedia from "Components/ContainerMedia/ContainerMedia";
import "./Faq.css";
import { Accordion } from "react-bootstrap";
import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import { useTranslation } from "react-i18next";

const Faq = () => {
  // TRANLATION AR && EN
  const { i18n, t } = useTranslation();
  const faq = [
    {
      id: 0,
      title:
        i18n.language === "ar"
          ? "ما الذي يجب علي مراعاته عند اختيار العقار؟"
          : "What should I consider when selecting a property?",
      text:
        i18n.language === "ar"
          ? "تشمل العوامل الرئيسية التي يجب مراعاتها الموقع والسعر والحجم وحالة العقار ووسائل الراحة في الحي والقرب من المدارس وأماكن العمل وقيمة إعادة البيع المستقبلية. من المهم أيضًا أن تأخذ بعين الاعتبار احتياجاتك على المدى الطويل وتفضيلات نمط حياتك."
          : "Key factors to consider include the location, price, size, condition of the property, neighborhood amenities, proximity to schools and workplaces, and future resale value. It's also important to consider your long-term needs and lifestyle preferences."
    },
    {
      id: 1,
      title:
        i18n.language === "ar"
          ? "كيف يمكنني التواصل مع البائع؟"
          : "How can I contact the seller?",
      text:
        i18n.language === "ar"
          ? "تتضمن كل قائمة عقارات زر اتصال. يمكنك ملء نموذج الاتصال الموجود على صفحة العقار، وسيقوم أحد ممثلينا بالاتصال بك قريبًا لتقديم المزيد من التفاصيل والإجابة على أي أسئلة قد تكون لديكم."
          : "Each property listing includes contact button. You can fill out the contact form on the property page, and one of our representatives will contact you shortly to provide more details and answer any questions you might have."
    },
    {
      id: 2,
      title:
        i18n.language === "ar"
          ? "ما هي خيارات التمويل المتاحة لشراء عقار؟"
          : "What financing options are available for buying a property?",
      text:
        i18n.language === "ar"
          ? "تشمل خيارات التمويل الشائعة الرهون العقارية التقليدية، وقروض إدارة الإسكان، والرهون العقارية ذات المعدل القابل للتعديل. يُنصح بالحصول على موافقة مسبقة للحصول على قرض قبل البدء في البحث عن عقار لمعرفة ميزانيتك وتحسين مصداقية عرضك."
          : "Common financing options include conventional mortgages, FHA loans, VA loans, and adjustable-rate mortgages (ARMs). It's advisable to get pre-approved for a loan before starting your property search to know your budget and improve your offer's credibility."
    },
    {
      id: 3,
      title:
        i18n.language === "ar"
          ? "كم تستغرق عملية الشراء؟"
          : "How long does the closing process take?",
      text:
        i18n.language === "ar"
          ? "تستغرق عملية الإغلاق عادةً ما بين 30 إلى 45 يومًا من وقت قبول عرضك. يمكن أن يختلف هذا الجدول الزمني بناءً على عوامل مثل مدى تعقيد المعاملة، وكفاءة المُقرض الخاص بك، وأي حالات طوارئ تحتاج إلى المعالجة."
          : "The closing process usually takes between 30 to 45 days from the time your offer is accepted. This timeline can vary based on factors such as the complexity of the transaction, the efficiency of your lender, and any contingencies that need to be addressed."
    },
    {
      id: 4,
      title:
        i18n.language === "ar"
          ? "هل يمكنني شراء عقار كأجنبي؟"
          : "Can I buy a property as a foreigner?",
      text:
        i18n.language === "ar"
          ? "نعم، يمكن للأجانب شراء العقارات في العديد من البلدان، على الرغم من أن العملية واللوائح قد تختلف. من المهم استشارة محامي عقارات محلي لفهم المتطلبات القانونية وأي قيود قد يتم تطبيقها."
          : "Yes, foreigners can purchase property in many countries, though the process and regulations may vary. It's important to consult with a local real estate attorney to understand the legal requirements and any restrictions that may apply."
    }
  ];

  return (
    <>
      <HelmetInfo
        titlePage={i18n.language === "ar" ? "الاسئلة الشائعة" : "FAQ"}
      />
      <div className="faq-home padding-top-50">
        {/* =============== START CONTAINER ============= */}
        <ContainerMedia>
          <div className="main-faq-title">
            <h2 className="title nexaBold-font">{t("FAQ")}</h2>
            <p className="text text-500">
              {i18n.language === "ar"
                ? "قم بملء النموذج و أحد مستشارينا العقاريين"
                : "Fill out the form and one of our property consultants will contact you."}
            </p>
          </div>
          {/* ============== START ALL FAQ HOME ============ */}
          <div className="all-faq-home">
            <Accordion>
              {faq.map((item) => {
                return (
                  <Accordion.Item className="faq-one" eventKey={`${item.id}`}>
                    <Accordion.Header>{item.title}</Accordion.Header>
                    <Accordion.Body className="text-500">
                      {item.text}
                    </Accordion.Body>
                  </Accordion.Item>
                );
              })}
            </Accordion>
          </div>
          {/* ============== END ALL FAQ HOME ============ */}
        </ContainerMedia>
        {/* =============== END CONTAINER ============= */}
      </div>
    </>
  );
};

export default Faq;
