

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBIp_vBWuNvJuaRiuktetAtadKyHNvebWY",
  authDomain: "aqar-eg.firebaseapp.com",
  databaseURL: "https://aqar-eg-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "aqar-eg",
  storageBucket: "aqar-eg.appspot.com",
  messagingSenderId: "516769240198",
  appId: "1:516769240198:web:2372797f0d8a5851cd2d07",
  measurementId: "G-FXRZBTZQ08"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const database = getDatabase(app);
const storage = getStorage(app); 

export { auth, database, storage }; 
