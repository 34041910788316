const MainAboutContent = ({ newClassRow, image, title, text, items }) => {
  return (
    <div className="main-about-content">
      {/* ============== START ROW ============== */}
      <div className={`row g-3 g-md-5 align-items-center ${newClassRow}`}>
        {/* ================ START COL ============= */}
        <div className="col-12 col-md-6 col-lg-4">
          {" "}
          {/* ============= START IMAGE ============== */}
          <div className="image-main-about overlay-bg-image--2 position-relative">
            <img
              src={image}
              alt="imageMainAbout"
              className="w-100 object-fit-cover"
              height={"429px"}
            />
          </div>
          {/* ============= END IMAGE ============== */}
        </div>
        {/* ================ END COL ============= */}
        {/* =============== START COL ============== */}
        <div className="col-12 col-md-6 col-lg-8">
          {/* ============= START CONTENT =========== */}
          <div className="main-content-info">
            <h2 className="title nexaBold-font">{title}</h2>
            <p className="text text-500">{text}</p>
            {items && items.length > 0 && (
              <div>
                {items.map((item, index) => (
                  <p className="text text-500" key={index}>
                    {item}
                  </p>
                ))}
              </div>
            )}
          </div>
          {/* ============= END CONTENT =========== */}
        </div>
        {/* =============== END COL ============== */}
      </div>
      {/* ============== END ROW ============== */}
    </div>
  );
};

export default MainAboutContent;
