import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import HeaderAbout from "./Components/HeaderAbout/HeaderAbout";
import CounterAbout from "./Components/CounterAbout/CounterAbout";
import MainAbout from "./Components/MainAbout/MainAbout";
import ContactUs from "Shared/ContactUs/ContactUs";
import ContainerMedia from "Components/ContainerMedia/ContainerMedia";
import { useTranslation } from "react-i18next";

const About = () => {
  // FOR TRANSLATION AR && EN
  const { t } = useTranslation();
  return (
    <>
      <HelmetInfo titlePage={t("about")} />
      <div className="about-page padding-top-50">
        <header>
          <HeaderAbout />
        </header>
        <main>
          {/* <CounterAbout /> */}
          <MainAbout />
          <ContainerMedia>
            <ContactUs />
          </ContainerMedia>
        </main>
      </div>
    </>
  );
};

export default About;
