import FurnishedIcon from "assets/images/IconsDetails/FurnishedIcon";
import GymIcon from "assets/images/IconsDetails/GymIcon";
import ParkingIcon from "assets/images/IconsDetails/ParkingIcon";
import PoolIcon from "assets/images/IconsDetails/PoolIcon";
import GoogleMapReact from "google-map-react";
import "./AboutTab.css";
import { useTranslation } from "react-i18next";
import MarkerIcon from "./MarkerIcon";
const AboutTab = ({ property}) => {
  const { i18n,t } = useTranslation();
  
  
  const defaultProps = {
    center: {
      lat: property.lat,
      lng: property.lng
    },
    zoom: 14
  };
  const statusTranslations = {
    'fully-finished': 'تشطيب كامل',
    'semi-finished': 'نصف تشطيب',
    'core and shell': 'على المحارة'
  };

  const translatedStatus = i18n.language === "ar" ? statusTranslations[property?.status] : property?.status;

  return (
    <div className="about-tab">
      <p className="text text-500">
        {i18n.language === "ar"
          ? property.text_ar
          : property.text_en}
      </p>
      <div className="list-info pt-4">
        <ul className="list-content d-flex align-items-center gap-3 flex-wrap m-0 p-0">
          <li>
              <div className="btn-main" style={{backgroundColor: "#00207e" ,color:"#ffff"}}>
                    {translatedStatus}
              </div>
          </li>
             {property.has_gym && (
          <li>
            <div className="btn-main">
              <GymIcon /> {t('gym')}
            </div>
          </li>
        )}

            {property.has_garden && (
              <li>
                <div className="btn-main">
                  {t('garden')}
                </div>
              </li>
            )}

            {property.has_pool && (
              <li>
                <div className="btn-main">
                  <PoolIcon /> {t('pool')}
                </div>
              </li>
            )}

            {property.parking && (
              <li>
                <div className="btn-main">
                  <ParkingIcon /> {t('parking')}
                </div>
              </li>
            )}

            {property.furnished && (
              <li>
                <div className="btn-main">
                  <FurnishedIcon /> {t('furnished')}
                </div>
              </li>
            )}
        </ul>
      </div>
      <div className="google-map-content">
        <div className="google-map-1 mt-4">
          <GoogleMapReact
            bootstrapURLKeys={{
              key: process.env.REACT_APP_GOOGLE_MAPS_KEY
            }}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
          >
            <MarkerIcon
              lat={defaultProps.center.lat}
              lng={defaultProps.center.lng}
              text={i18n.language === "ar"
                ? property.city.name_ar
                : property.city.name_en}
            />
          </GoogleMapReact>
        </div>
        <h2 className="title">{i18n.language === "ar"
                ? property.city.name_ar
                : property.city.name_en}</h2>
      </div>
    </div>
  );
};

export default AboutTab;
