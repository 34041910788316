import React, { useState } from "react";
import { useField } from "formik";
import "./Forms.css"

const InputFiled = ({ label, success, ...props }) => {
  // @ts-ignore
  const [field, meta, helpers] = useField(props);
  const [focused, setFocused] = useState(false);

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = e => {
    helpers.setTouched(true);
    setFocused(false);
  };

  const isError = meta.touched && meta.error;
  const isSuccess = success && meta.touched && !meta.error;

  return (
    <div
      className={`form-group input-field-info d-flex flex-column gap-1  position-relative form-one ${isError
        ? "has-error"
        : ""}`}
    >
      <label
        htmlFor={props.id || props.name}
        className={`form-label text-500 ${focused ||
        (field.value && field.value.length > 0)
          ? "active"
          : ""}`}
      >
        {label}
      </label>
      <input
        {...field}
        {...props}
        value={field.value || ""}
        onChange={e => {
          helpers.setValue(e.target.value);
        }}
        className={`input-field form-control ${meta.touched && meta.error
          ? "is-invalid"
          : isSuccess ? "active-border" : ""}`}
        required
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      {meta.touched &&
        meta.error &&
        <div className="error">
          {meta.error}
        </div>}
    </div>
  );
};

export default InputFiled;
