// @ts-nocheck
import { useState, useEffect } from "react";
import { fetchProperties } from "../services/propertyService";
import { useNavigate } from "react-router-dom";


const useProperties = ({type="",lowestPrice="",highestPrice="",page=0,rowsPerPage=12,orderBy="",bedNum="",city=""}) => {
  const [properties, setproperties] = useState(null);
  const [count, setCount] = useState(null);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const getData = async () => {
    try {
      const {results} = await fetchProperties({type,lowestPrice,highestPrice,page,rowsPerPage,orderBy,bedNum,city});
      setproperties(results.data);
      setCount(results.totalCount)
      setLoading(false);

      updateParams()
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  useEffect(() => {

    getData();
  }, [type,page,rowsPerPage,orderBy]);


  const updateParams=()=>{
    if(window.location.pathname!=="/"){
      const url = new URL(window.location);
      url.searchParams.set("rowsPerPage", rowsPerPage);
      url.searchParams.set("page", page);
      url.searchParams.set("type", type)
      url.searchParams.set("orderBy", orderBy)
      url.searchParams.set("bedNum", bedNum)
      url.searchParams.set("city", city)
      url.searchParams.set("lowestPrice", lowestPrice)
      url.searchParams.set("highestPrice", highestPrice)

      window.history.pushState({}, "", url);
    }
  }
  const showSearchResults=async ({bedNum="",city="",type="",lowestPrice,highestPrice})=>{
    navigate(`/results?type=${type}&bedNum=${bedNum}&city=${city}`+
      `&lowestPrice=${lowestPrice}&highestPrice=${highestPrice}`)
  }
  const updateSearchResults=async ({bedNum,city,type,lowestPrice,highestPrice})=>{
    try {

      const {results} = await fetchProperties({type,lowestPrice,highestPrice,bedNum,city});
      setproperties(results.data);
      setCount(results.totalCount)
      setLoading(false);
      const url = new URL(window.location);
      url.searchParams.set("rowsPerPage", rowsPerPage);
      url.searchParams.set("page", page);
      url.searchParams.set("type", type)
      url.searchParams.set("orderBy", orderBy)
      url.searchParams.set("bedNum", bedNum)
      url.searchParams.set("city", city)
      url.searchParams.set("lowestPrice", lowestPrice)
      url.searchParams.set("highestPrice", highestPrice)

      window.history.pushState({}, "", url);
      } catch (error) {
      console.log(error);
      setError(error);
      setLoading(false);
    }
  }
  return {showSearchResults, properties, loading, error ,count,updateSearchResults,getData};
};
export default useProperties;
