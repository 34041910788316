import React, { useState } from "react";
import { useField } from "formik";
import PropTypes from "prop-types";
import "./Forms.css";

const TextAreaInput = ({ label, success, ...props }) => {
  // @ts-ignore
  const [field, meta, helpers] = useField(props);

  const [focused, setFocused] = useState(false);

  // ON FOCUS INPUT 
  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = e => {
    helpers.setTouched(true);
    setFocused(false);
  };
  const isError = meta.touched && meta.error; // THIS ON TOUCHED INPUT MAKE ERROR
  const isSuccess = success && meta.touched && !meta.error; // IF INPUT SUCCESS ADD SUCCESS
  // CUSTOM STYLE INPUT
  const inputStyles = {
    borderColor: isError ? "#dc3545" : isSuccess ? "green" : "",
    height: "160px"
  };
  return (
    <div
      className={`input-field-info text-area-input d-flex flex-column gap-1  form-one  ${meta.touched &&
      meta.error
        ? "is-invalid"
        : ""}`}
    >
      <label
        htmlFor={props.id || props.name}
        className={`form-label text-500 ${focused ||
        (field.value && field.value.length > 0)
          ? "active"
          : ""}`}
      >
        {label}
      </label>
      <textarea
        {...field}
        {...props}
        style={inputStyles}
        className={`input-field form-control ${meta.touched && meta.error
          ? "is-invalid"
          : isSuccess ? "active-border" : ""}`}
        onChange={e => {
          helpers.setValue(e.target.value);
        }}
        required
        onFocus={handleFocus}
        onBlur={handleBlur}
      />

      {meta.touched && meta.error
        ? <div className="error">
            {meta.error}
          </div>
        : null}
    </div>
  );
};

// PropTypes for type checking
TextAreaInput.propTypes = {
  label: PropTypes.string.isRequired,
  success: PropTypes.bool // Add more specific PropTypes as needed
};

export default TextAreaInput;
