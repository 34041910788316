import ContainerMedia from "Components/ContainerMedia/ContainerMedia";
import HeaderTopContent from "../HeaderTopContent/HeaderTopContent";
import ProductsCardsResults from "../ProductsCardsResults/ProductsCardsResults";
import MapLocationResults from "../MapLocationResults/MapLocationResults";
import { useEffect, useState } from "react";
import PaginationPage from "Components/Pagination/Pagination";

const AllInfoResults = ({handleOrderByChange,rowsPerPage,page,handleChangePage,loading,properties,count}) => {

  const [showMap, setShowMap] = useState(
    localStorage.getItem("showMap") === "true" ? true : false
  );

  const [hideProducts, setHideProducts] = useState(
    localStorage.getItem("hideProducts") === "true" ? true : false
  );

  useEffect(() => {
    localStorage.setItem("showMap", showMap.toString());
  }, [showMap]);

  useEffect(() => {
    localStorage.setItem("hideProducts", hideProducts.toString());
  }, [hideProducts]);

  const handleCheckboxClick = () => {
    setShowMap(!showMap);
    setHideProducts(false)
  };

  const handleFullScreen = () => {
    setHideProducts(!hideProducts);
  };

  return (
    <div className="all-info-results padding-top-50">
      <ContainerMedia>
        <HeaderTopContent
          count={count}
          handleOrderByChange={handleOrderByChange}
          showMap={showMap}
          handleCheckboxClick={handleCheckboxClick}
        />
      
        <div className="all-content-results padding-top-30">
          <div className="row g-3">
          
            <div
              className={`col-12 ${
                hideProducts ? "d-none" : showMap ? "col-lg-7" : "col-lg-12"
              }`}
            >
              <ProductsCardsResults loading={loading} properties={properties} showMap={showMap} />
              <PaginationPage
                handleChangePage={handleChangePage}
                page={page}
                rowsPerPage={rowsPerPage}
                count={count}
                />
            </div>
         

            {showMap && (
              <>
              
                <div
                  className={`col-12 ${
                    hideProducts ? "col-lg-12 active-full" : "col-lg-5"
                  }`}
                >
                  <MapLocationResults properties={properties}  handleFullScreen={handleFullScreen} />
                </div>
               
              </>
            )}
          </div>
        </div>
      
      </ContainerMedia>
   
    </div>
  );
};

export default AllInfoResults;
