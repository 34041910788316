import { Link } from "react-router-dom";

const LogoInfo = ({logo}) => {
  return (
    <>
      {" "}
      <Link to="/" className="image-logo">
        <img src={logo} alt="logo" className="object-fit-cover w-100 h-100" />
      </Link>
    </>
  );
};

export default LogoInfo;
