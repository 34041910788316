import { Helmet } from "react-helmet-async";

const HelmetInfo = ({ titlePage }) => {
  return (
    <Helmet>
      <title>{titlePage}</title>

      {/* description */}
      <meta
        name="description"
        content="aqar  Discription Content info aqar   Discription Content info aqar  Discription Content info"
      />

      {/* keywords */}
      <meta
        name="keywords"
        content="aqar   KeyWords aqar   KeyWords aqar   KeyWords"
      />
      {/*  <!-- #author --> */}
      <meta name="author" content="aqar  Website" />
      {/* <!-- Facebook Meta Tags --> */}
      <meta property="og:url" content="aqar  Link Url" />
      <meta property="og:type" content="aqar  website" />
      <meta property="og:title" content="aqar  Services" />
      <meta
        property="og:description"
        content="aqar  A concise description of your page content"
      />
      <meta property="og:image" content="aqar  Image" />

      {/* Twitter Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content="aqar  Domain Link Url" />
      <meta property="twitter:url" content="aqar  Url" />
      <meta name="twitter:title" content="aqar " />
      <meta
        name="twitter:description"
        content="aqar  description of your page content"
      />
      <meta name="twitter:image" content="aqar   Image" />

      {/* LINK URL WEBSITE aqar  */}
      <link rel="canonical" href="https://www.example.com/your-page" />
      <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo192.png" />
      <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
    </Helmet>
  );
};

export default HelmetInfo;
