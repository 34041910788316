
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button } from "react-bootstrap";
import ContainerMedia from "Components/ContainerMedia/ContainerMedia";
import PaginationPage from "Components/Pagination/Pagination";
import useProperties from "hooks/useProperties";
import usePaginationParams from "hooks/usePaginationParams";
import { deletePropertyById } from "services/propertyService";

const PropertyManagement = () => {
  const { page, handleChangePage ,rowsPerPage}=usePaginationParams()
  const{ properties, loading, getData,count }= useProperties({rowsPerPage,page})
  let masterPlanPhoto;
   const imagesToShow = [];
    properties?.photos?.forEach(photo => {
      if (photo.isMasterPlan) {
        masterPlanPhoto = photo;
      } else {
        imagesToShow.push(photo);
      }
    });

  const deleteProperty = async (id) => {
    try {
      const res = await deletePropertyById(id);
      if (res.id === id) { 
        getData();
        toast.success("Property deleted successfully");
      } else {
        toast.error("Failed to delete property");
      }
    } catch (error) {
      toast.error("An error occurred while deleting the property");
      console.error("Error deleting property:", error);
    }
  };



  return (
    <div className="all-padding">
      <ContainerMedia>
        <h2 className="title-admin pb-3">Properties Management</h2>
        <Link to="/admin" className="btn-main mb-4">
          Add New Property
        </Link>
        {loading ? (
          <div className="d-flex justify-content-center align-items-center">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : count === 0 ? (
          <p className="admin-error-data">No properties available</p>
        ) : (
          <>
            {count > 0 ? (
              <h2 className="admin-error-data num-count-info">
                properties : {count}
              </h2>
            ) : (
              ""
            )}
            <div className="table-content-info">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Property Name (Arabic)</th>
                    <th>Property Name (English)</th>
                    {/* <th>Number of Bathrooms</th> */}
                    {/* <th>Number of Rooms</th> */}
                    <th>Price</th>
                    {/* <th>Square Meters</th> */}
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {properties?.map((property) => (
                    <tr key={property.id}>
                      <td className="message-cell">{property.id}</td>
                      <td className="message-cell">
                        {property.title_ar}
                      </td>
                      <td className="message-cell">
                        {property.title_en}
                      </td>
                    
                      {/* <td className="message-cell">
                        {property.bathNum}
                      </td>
                      <td className="message-cell">
                        {property.bedNum}
                      </td> */}
                      <td className="message-cell">{property.price}</td>
                      {/* <td className="message-cell">{property.space}</td> */}
                      <td>
                        <div className="d-flex align-items-center gap-3">
                          <Link
                            to={`/admin/edit/${property?.id}`}
                            className="btn-content-edit btn btn-sm btn-primary"
                          >
                            Edit
                          </Link>
                          <Button
                            className="btn-content-edit"
                            variant="danger"
                            size="sm"
                            onClick={() => deleteProperty(property.id)}
                          >
                            Delete
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <PaginationPage
                handleChangePage={handleChangePage}
                page={page}
                rowsPerPage={rowsPerPage}
                count={count}
                />
          </>
        )}
      
      </ContainerMedia>
    </div>
  );
};

export default PropertyManagement;
