import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import BreadcrumbsPage from "Components/Ui/BreadcrumbsPage/BreadcrumbsPage";
import HeaderDetailsProperties from "./Components/HeaderDetailsProperties/HeaderDetailsProperties";
import ImagesProperties from "./Components/ImagesProperties/ImagesProperties";
import MiddleContent from "./Components/MiddleContent/MiddleContent";
import {  useParams } from "react-router-dom";
import "./DetailsProperties.css";
import { useTranslation } from "react-i18next";
import NotDataFound from "Components/Ui/NotDataFound/NotDataFound";
import LoaderApi from "Components/LoaderApi/LoaderApi";
import useDetailsPageClass from "../../hooks/useDetailsPageClass";
import useProperty from "../../hooks/useProperty"
const DetailsProperties = () => {
  const { i18n,t } = useTranslation();
  const {id}=useParams()
  const{ property, loading, error }=useProperty(id)
  useDetailsPageClass();
console.log(property);
  if (error) {
    return <NotDataFound />;
  }
  return (
    <>
      <HelmetInfo
        titlePage={i18n.language === "ar" ? property?.title_ar : property?.title_en}
      />

      <header>
        <div className="padding-top-30">
          <BreadcrumbsPage
            newClassBread={"bread"}
            routeTitleTwoBread={"/properties"}
            titleTwoBread={t("PropertiesDetails")}
            textBreadActive={
           i18n.language === "ar"
          ? property?.title_ar
          : property?.title_en}
            isTruePageRoute={true}
          />
        </div>
      </header>
      {loading ? (
        <LoaderApi />
      ) : (
      <main>
      <HeaderDetailsProperties property={property} i18n={i18n}/> 
      <ImagesProperties images={property.photos}/>
      <MiddleContent property={property}/>
      {/* <MiddleContent property={property}/> */}
          {/* <HeaderDetailsProperties property={property} i18n={i18n}/> 
          <ImagesProperties images={property.photos}/>
          <MiddleContent property={property}/> */}
      </main>
    )}
    </>
  );
};

export default DetailsProperties;
