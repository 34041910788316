import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";

const CounterUp = ({ numberInfo, showTypeCounter, typeCounter }) => {
  const [ref, inView] = useInView({
    triggerOnce: false
  });
  return (
    <div
      className={`counter-up  text-center  nexaBold-font  d-flex  align-items-center  gap-1 justify-content-center`}
      ref={ref}
      style={{ fontSize: "32px", fontWeight: "900" }}
    >
    {showTypeCounter &&
      <div style={{ fontSize: "20px", fontWeight: "800" }}>
        {typeCounter}
      </div>}
    {inView && <CountUp end={numberInfo} duration={2} />}
    
    </div>
  );
};

export default CounterUp;
