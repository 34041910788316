import logo from "../../assets/images/logo/logo.svg";
import LogoInfo from "./LogoInfo";

const LogoNavbar = () => {
  return (
    <>
      <LogoInfo logo={logo} />
    </>
  );
};

export default LogoNavbar;
