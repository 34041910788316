import React, { useState, useEffect, useRef } from "react";
import "./DropDownMenuFilter.css";
import arrowIcon from "../../assets/images/iconsFilter/arrowDown.svg";
const DropDownMenuFilter = ({
  newClassButton,
  isShowIconLeftDrop,
  newClassListDrop,
  iconDrop,
  children,
  checkedLabel
}) => {
  // SHOW DROPMENU FILTER
  const [isOpen, setIsOpen] = useState(false);
  // USE REF TO SHOW IF CLIKED OUTSIDE DROPMENU CLOSE MENU
  const dropdownRef = useRef(null);

  // TOGGLE DROPMENU
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // ONCLIKED OUTSIDE DROPMENU CLOSE MENU
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  // ONCLIKED OUNTSIDE DROPMENU
  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="dropdown---1" ref={dropdownRef}>
      <button
        className={`${newClassButton} btn-main btn-transparent`}
        onClick={toggleDropdown}
      >
        <span className="info-drop-menu-btn d-flex align-items-center gap-2">
          {isShowIconLeftDrop && (
            <img width={"20px"} height={"20px"} src={iconDrop} alt="icon" />
          )}{" "}
          {checkedLabel}
        </span>{" "}
        <img src={arrowIcon} alt="icon arrow" width={"16px"} height={"16px"} />
      </button>
      {isOpen && (
        <div className={`all-drop-menu-list ${newClassListDrop}`}>
          <ul className="dropdown-menu---1">{children}</ul>
        </div>
      )}
    </div>
  );
};

export default DropDownMenuFilter;
