// @ts-nocheck
import "./FilterCategory.css";
import SeacrchIcon from "assets/images/iconsSvg/SeacrchIcon";
import BedFilter from "./BedFilter";
import PropertyFilter from "./PropertyFilter";
import PriceFilter from "./PriceFilter";
import CitySearchFilter from "./CitySearchFilter";

import { useReducer } from "react";
const DEFAULT_LOWEST_PRICE=1000000
const DEFAULT_HIGHEST_PRICE=7000000


const reducer = (state, action) => {
  switch (action.type) {
    case "SET_CITY":
      return { ...state, city: action.payload };
    case "SET_BED_NUM":
      return { ...state, bedNum: action.payload };
    case "SET_TYPE":
      return { ...state, type: action.payload };
    case "SET_LOWEST_PRICE":
      return { ...state, lowestPrice: action.payload };
    case "SET_HIGHEST_PRICE":
      return { ...state, highestPrice: action.payload };
    default:
      return state;
  }
};

const FilterCategory = ({bedNum,city,highestPrice,lowestPrice,type,showSearchResults}) => {
  const [state, dispatch] = useReducer(reducer, {
    city,
    bedNum: Number(bedNum)||"",
    type,
    lowestPrice: Number(lowestPrice)||DEFAULT_LOWEST_PRICE,
    highestPrice: Number(highestPrice)||DEFAULT_HIGHEST_PRICE,
  });
  const handleSearch = () => {
  const{city,bedNum,type,lowestPrice,highestPrice}=state
   showSearchResults({city,bedNum,type,lowestPrice,highestPrice})
  };
  
  return (
    <>
      <div className="filter-category">
        <CitySearchFilter
          city={state.city}
          setCity={(city) => dispatch({ type: "SET_CITY", payload: city })}
        />
        <BedFilter
          bedNum={state.bedNum}
          setBedNum={(bedNum) => dispatch({ type: "SET_BED_NUM", payload: bedNum })}
        />
        <PropertyFilter
        type={type}
          setType={(type) => dispatch({ type: "SET_TYPE", payload: type })}
        />
        <PriceFilter
          lowestPrice={lowestPrice}
          highestPrice={highestPrice}
          setLowestPrice={(lowestPrice) => dispatch({ type: "SET_LOWEST_PRICE", payload: lowestPrice })}
          setHighestPrice={(highestPrice) => dispatch({ type: "SET_HIGHEST_PRICE", payload: highestPrice })}
        />
        <div onClick={handleSearch} className="search-filter">
          <SeacrchIcon />
        </div>
      </div>
    </>
  );
};

export default FilterCategory;
