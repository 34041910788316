import ContainerMedia from "Components/ContainerMedia/ContainerMedia";
import "./Footer.css";
import LeftContent from "./Components/LeftContent";
import RightContent from "./Components/RightContent";
import MiddleContent from "./Components/MiddleContent";
import { useTranslation } from "react-i18next";
const Footer = () => {
  const { t } = useTranslation(); // FOR TRANSLATION AR && EN
  return (
    <footer>
      {/* ============== START SECTION FOOTER =============== */}
      <div className="footer mt-5">
        {/* ============== START CONTAINER ============= */}
        <ContainerMedia>
          {/* =============== START ALL FOOTER ============ */}
          <div className="all-footer">
            {/* =========== START ROW =========== */}
            <div className="row  g-4">
              {/* ============= START COL =========== */}
              <div className="col-12 col-md-4 col-lg-4">
                <LeftContent />
              </div>
              {/* ============= END COL =========== */}
              {/* ============= START COL =========== */}
              <div className="col-12 col-md-3 col-lg-4">
                <MiddleContent />
              </div>
              {/* ============= END COL =========== */}
              {/* ============= START COL =========== */}
              <div className="col-12 col-md-5 col-lg-4">
                <RightContent />
              </div>
              {/* ============= END COL =========== */}
            </div>
            {/* =========== END ROW =========== */}
          </div>
          {/* =============== END ALL FOOTER ============ */}
          <p className="text-copyright">{t("copyRightText")}</p>
        </ContainerMedia>
        {/* ============== END CONTAINER ============= */}
      </div>
      {/* ============== END SECTION FOOTER =============== */}
    </footer>
  );
};

export default Footer;
