
const CheckReadyIcon = () => {
  return (
    <><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_23375_1082)">
    <path d="M14.6663 7.39048V8.00381C14.6655 9.44143 14.2 10.8403 13.3392 11.9917C12.4785 13.1431 11.2685 13.9855 9.88991 14.3931C8.5113 14.8007 7.03785 14.7517 5.68932 14.2535C4.3408 13.7553 3.18944 12.8345 2.40698 11.6285C1.62452 10.4225 1.25287 8.99585 1.34746 7.56135C1.44205 6.12685 1.99781 4.76136 2.93186 3.66853C3.86591 2.57569 5.1282 1.81407 6.53047 1.49724C7.93274 1.18042 9.39985 1.32537 10.713 1.91048M14.6663 2.66666L7.99967 9.34L5.99967 7.34" stroke="#00207E" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_23375_1082">
    <rect width="16" height="16" fill="white"/>
    </clipPath>
    </defs>
    </svg>
    </>
  )
}

export default CheckReadyIcon