import DropDownMenuFilter from "Shared/DropDownMenuFilter/DropDownMenuFilter";
import homeIcon from "../../../assets/images/iconsFilter/buliding.svg";
import VillaIcon2 from "assets/images/properties/VillaIcon2";
import HomeIcon from "assets/images/properties/HomeIcon";
import DuplexIcon1 from "assets/images/properties/DuplexIcon1";
import CompoundIcon1 from "assets/images/properties/CompoundIcon1";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const PropertyFilter = ({ setType,type }) => {
  // FOR TRANSLATION AR && EN
  const { i18n, t } = useTranslation();

  // ARRAY INCLUDE CATEGORY
  const propertyTypes = [
    // { icon: <VillaIcon2 />, label: t("Villa"), value: "Villa" },
    { icon: <HomeIcon />, label: t("Apartment"), value: "Apartment" },
    { icon: <DuplexIcon1 />, label: t("Chalet"), value: "Chalet" },
  ];

  // CHANGE TEXT ON CHANGE DIRECTION
  const getTextProButton = (language) =>
    language === "ar" ? "نوع العقار" : "Property type";
  const [buttonText, setButtonText] = useState(type||getTextProButton(i18n.language));

  useEffect(() => {
    const handleLanguageChange = (lang) => {
      // ADD CHANGE LABEL LANGUAGES ON CHANGE DIRECTION
      setButtonText(getTextProButton(lang));
    };

    i18n.on("languageChanged", handleLanguageChange);

    return () => {
      i18n.off("languageChanged", handleLanguageChange);
    };
  }, [i18n]);

  const handleItemClick = (label, value) => {
    setButtonText(label);
    setType(value); // Set the type when an item is clicked
  };

  return (
    <>
      <div className="drop-filter-one">
        <DropDownMenuFilter
          newClassButton={"button-drop"}
          newClassListDrop={"property-filter-content w-100"}
          isShowIconLeftDrop={true}
          iconDrop={homeIcon}
          children={
            <>
              {propertyTypes.map((property, index) => (
                <li
                  key={index}
                  className="dropdown-item---1 d-flex align-items-center gap-2"
                  onClick={() => handleItemClick(property.label, property.value)}
                >
                  {property.icon} {property.label}
                </li>
              ))}
            </>
          }
          checkedLabel={buttonText}
        />
      </div>
    </>
  );
};

export default PropertyFilter;
