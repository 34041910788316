import React from 'react'

const SeacrchIcon = () => {
  return (
    <><svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.0846 18.1224C5.3763 18.1224 1.54297 14.2891 1.54297 9.58073C1.54297 4.8724 5.3763 1.03906 10.0846 1.03906C14.793 1.03906 18.6263 4.8724 18.6263 9.58073C18.6263 14.2891 14.793 18.1224 10.0846 18.1224ZM10.0846 2.28906C6.05964 2.28906 2.79297 5.56406 2.79297 9.58073C2.79297 13.5974 6.05964 16.8724 10.0846 16.8724C14.1096 16.8724 17.3763 13.5974 17.3763 9.58073C17.3763 5.56406 14.1096 2.28906 10.0846 2.28906Z" fill="white"/>
    <path d="M18.8326 18.9615C18.6742 18.9615 18.5159 18.9031 18.3909 18.7781L16.7242 17.1115C16.4826 16.8698 16.4826 16.4698 16.7242 16.2281C16.9659 15.9865 17.3659 15.9865 17.6076 16.2281L19.2742 17.8948C19.5159 18.1365 19.5159 18.5365 19.2742 18.7781C19.1492 18.9031 18.9909 18.9615 18.8326 18.9615Z" fill="white"/>
    </svg>
    </>
  )
}

export default SeacrchIcon