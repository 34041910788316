import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import "./LanguagesDropMenu.css";
import arLang from "../../../assets/images/lang/ar.svg";
import enLang from "../../../assets/images/lang/en.svg";
import i18next from "i18next";
import Cookies from "js-cookie";

const LanguagesDropMenu = () => {
  // DEFAULT LANG
  const [selectedLang, setSelectedLang] = useState("ar");

  //  FUNCTION TO SELECT LANGUGE
  const handleLanguageChange = (langCode) => {
    setSelectedLang(langCode);
    i18next.changeLanguage(langCode); // CHANGE LANGUAGE USING I18NEXT
    Cookies.set("selectedLang", langCode); // SAVE IN COOKIES
    updateHtmlAttributes(langCode);
  };

  // FUNCTION TO UPDATE HTML LANG AND DIR 
  const updateHtmlAttributes = (langCode) => {
    document.documentElement.lang = langCode; //  UPADTE HTML LANG "AR" AND "EN"
    document.documentElement.dir = langCode === "ar" ? "rtl" : "ltr"; // UPDATE TEXT DIRECTION
  };

  // LOAD LANGUAGE FROM COOKIES AND SET IN HTML LANG
  useEffect(() => {
    const storedLang = Cookies.get("selectedLang")||selectedLang;
      setSelectedLang(storedLang); 
      i18next.changeLanguage(storedLang); 
      updateHtmlAttributes(storedLang); 
  }, []);

  // UPDATE LANGUAGE HTML LANG AND DIR
  useEffect(() => {
    updateHtmlAttributes(selectedLang);
  }, [selectedLang]);

  return (
    <div className="dropmenu-lang">
      <Dropdown>
        <Dropdown.Toggle id="dropdown-basic-loading" className="drop-lang">
          <div className="lang-default d-flex gap-2 align-items-center justify-content-center">
            {selectedLang === "ar" ? "العربية" : "En"}
            <img
              src={selectedLang === "ar" ? arLang : enLang}
              alt="language icon"
            />
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu>

          <Dropdown.Item onClick={() => handleLanguageChange("ar")}>
            <div className="lang-drop d-flex gap-2 align-items-center justify-content-end">
              العربية
              <img src={arLang} alt="language icon" />
            </div>
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleLanguageChange("en")}>
            <div className="lang-drop d-flex gap-2 align-items-center justify-content-end">
              En
              <img src={enLang} alt="language icon" />
            </div>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default LanguagesDropMenu;
