import ContainerMedia from "Components/ContainerMedia/ContainerMedia";
import imageHeaderContact from "../../../../assets/images/contact/imageContact.png";
const ImageHeader = () => {
  return (
    <ContainerMedia>
      <div className="image-header-contact">
        <img
          src={imageHeaderContact}
          alt="imageheadercontact"
          className="w-100 object-fit-cover rounded-4"
          height={"450px"}
        />
      </div>
    </ContainerMedia>
  );
};

export default ImageHeader;
