import React, { useEffect, useState } from "react";
import DropDownMenuFilter from "Shared/DropDownMenuFilter/DropDownMenuFilter";
import locationIcon from "../../../assets/images/iconsFilter/send.svg";
import CitySearch from "./CitySearch";
import { useTranslation } from "react-i18next";
import useCities from "hooks/useCities";

const CitySearchFilter = ({setCity,city}) => {
  const {i18n} =useTranslation()
  const [isCitySelected, setIsCitySelected] = useState(false);
  const { cities } = useCities();
  const [selectedCity, setSelectedCity] = useState(city);
   useEffect(()=>{
     if(city&&cities.length>0){
        setIsCitySelected(true)
         let  defaultCity=cities.find((item)=>item.id===Number(city))
        setSelectedCity( i18n.language === "ar" ?defaultCity.name_ar : defaultCity.name_en);
      }
   },[cities])
  const handleCitySelect = (city) => {
    setSelectedCity( i18n.language === "ar" ?city.name_ar:city.name_en);
    setIsCitySelected(true);
    setCity(city.id)
  };

  return (
    <div className="drop-filter-one">
      <DropDownMenuFilter
        newClassButton={"button-drop"}
        newClassListDrop={"search-city-filter"}
        isShowIconLeftDrop={true}
        iconDrop={locationIcon}
        checkedLabel={isCitySelected ? selectedCity : i18n.language === "ar" ? "المنطقة":"Location"}
        children={
          <CitySearch i18n={i18n} listings={cities} onCitySelect={handleCitySelect} />
        }
      />
    </div>
  );
};

export default CitySearchFilter;
