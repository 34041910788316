import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import CheckboxInput from "Components/Ui/Checkbox/CheckboxInput";
import "./DropMenuContent.css";

const DropMenuContent = ({handleChange, newClassButton, textButton, checkboxItems }) => {
  const [selectedCheckbox, setSelectedCheckbox] = React.useState(null);
  const handleCheckboxChange = (item) => {
    setSelectedCheckbox(item.id);
    handleChange(item)
  };

  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {(popupState) => (
        <React.Fragment>
          <button
            className={`${newClassButton} btn-main btn-transparent`}
            {...bindTrigger(popupState)}
          >
            {textButton}
          </button>
          <Menu {...bindMenu(popupState)}>
            {checkboxItems.map((checkboxItem, index) => (
              <MenuItem key={index}>
                <CheckboxInput
                  id={checkboxItem.id}
                  label={checkboxItem.label}
                  isChecked={selectedCheckbox === checkboxItem.id}
                  changeButton={() => handleCheckboxChange(checkboxItem)}
                />
              </MenuItem>
            ))}
          </Menu>
        </React.Fragment>
      )}
    </PopupState>
  );
};

export default DropMenuContent;
