import React from 'react'

const CompoundIcon1 = () => {
  return (
    <><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.58465 15.6263H3.45966C1.92632 15.6263 1.04297 14.7429 1.04297 13.2096L1.04297 3.45966C1.04297 1.92632 1.92632 1.04297 3.45966 1.04297L7.04298 1.04297C8.57631 1.04297 9.45961 1.92632 9.45961 3.45966V5.0013C9.45961 5.34297 9.17628 5.6263 8.83461 5.6263C8.49295 5.6263 8.20961 5.34297 8.20961 5.0013V3.45966C8.20961 2.60966 7.89298 2.29297 7.04298 2.29297L3.45966 2.29297C2.60966 2.29297 2.29297 2.60966 2.29297 3.45966L2.29297 13.2096C2.29297 14.0596 2.60966 14.3763 3.45966 14.3763H5.58465C5.92631 14.3763 6.20965 14.6596 6.20965 15.0013C6.20965 15.343 5.92631 15.6263 5.58465 15.6263Z" fill="#969595"/>
    <path d="M12.4654 18.9583H7.59868C5.92368 18.9583 4.95703 17.9917 4.95703 16.3167L4.95703 7.01665C4.95703 5.34165 5.92368 4.375 7.59868 4.375L12.4654 4.375C14.1404 4.375 15.0987 5.34165 15.0987 7.01665L15.0987 16.3167C15.0987 17.9917 14.1404 18.9583 12.4654 18.9583ZM7.59868 5.625C6.59868 5.625 6.20703 6.01665 6.20703 7.01665L6.20703 16.3167C6.20703 17.3167 6.59868 17.7083 7.59868 17.7083H12.4654C13.457 17.7083 13.8487 17.3167 13.8487 16.3167L13.8487 7.01665C13.8487 6.01665 13.457 5.625 12.4654 5.625L7.59868 5.625Z" fill="#969595"/>
    <path d="M16.5429 15.6263H14.4763C14.1346 15.6263 13.8513 15.343 13.8513 15.0013C13.8513 14.6596 14.1346 14.3763 14.4763 14.3763H16.5429C17.3929 14.3763 17.7096 14.0596 17.7096 13.2096V3.45966C17.7096 2.60966 17.3929 2.29297 16.5429 2.29297L12.9596 2.29297C12.1096 2.29297 11.793 2.60966 11.793 3.45966V5.0013C11.793 5.34297 11.5096 5.6263 11.168 5.6263C10.8263 5.6263 10.543 5.34297 10.543 5.0013V3.45966C10.543 1.92632 11.4263 1.04297 12.9596 1.04297L16.5429 1.04297C18.0763 1.04297 18.9596 1.92632 18.9596 3.45966V13.2096C18.9596 14.7429 18.0763 15.6263 16.5429 15.6263Z" fill="#969595"/>
    <path d="M11.6654 9.79297H8.33203C7.99036 9.79297 7.70703 9.50964 7.70703 9.16797C7.70703 8.8263 7.99036 8.54297 8.33203 8.54297H11.6654C12.007 8.54297 12.2904 8.8263 12.2904 9.16797C12.2904 9.50964 12.007 9.79297 11.6654 9.79297Z" fill="#969595"/>
    <path d="M11.6654 12.293H8.33203C7.99036 12.293 7.70703 12.0096 7.70703 11.668C7.70703 11.3263 7.99036 11.043 8.33203 11.043H11.6654C12.007 11.043 12.2904 11.3263 12.2904 11.668C12.2904 12.0096 12.007 12.293 11.6654 12.293Z" fill="#969595"/>
    <path d="M10 18.957C9.65833 18.957 9.375 18.6737 9.375 18.332V15.832C9.375 15.4904 9.65833 15.207 10 15.207C10.3417 15.207 10.625 15.4904 10.625 15.832V18.332C10.625 18.6737 10.3417 18.957 10 18.957Z" fill="#969595"/>
    </svg></>
  )
}

export default CompoundIcon1