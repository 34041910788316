import imageContact from "../../../assets/images/contact/contact.png";

const ImageContact = () => {
  return (
    <div className="image-contact h-100">
      <img
        src={imageContact}
        alt="imageContact"
        className="w-100 h-100 object-fit-cover"

      />
    </div>
  );
};

export default ImageContact;
