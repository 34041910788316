import ContainerMedia from "Components/ContainerMedia/ContainerMedia";
import TabsContentDetails from "./TabsContentDetails/TabsContentDetails";
import RightContentDetails from "./RightContentDetails/RightContentDetails";

const MiddleContent = ({property}) => {
  return (
    <div className="middle-content-details padding-top-50">
      {/* ================ START CONTAINER ================== */}
      <ContainerMedia>
        {/* ================== START ROW ============== */}
        <div className="row  g-md-3 g-lg-4">
          {/* ================= START COL ================== */}
          <div className="col-12 col-md-12 col-lg-8">
            <TabsContentDetails property={property}/>
          </div>
          {/* ================= END COL ================== */}

          {/* ================= START COL ================== */}
          <div className="col-12 col-md-12 col-lg-4">
            {/* ================= START RIGHT CONTEWNT DETAILS ============= */}

            <RightContentDetails property={property} />

            {/* ================= END RIGHT CONTEWNT DETAILS ============= */}
          </div>
          {/* ================= END COL ================== */}
        </div>
        {/* ================== END ROW ============== */}
      </ContainerMedia>
      {/* ================ END CONTAINER ================== */}
    </div>
  );
};

export default MiddleContent;
