import React from 'react'

const LocationIcon = () => {
  return (
    <><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.851 17.0225C9.86771 17.0225 8.47604 16.3308 7.37604 13.0225L6.77604 11.2225L4.97604 10.6225C1.67604 9.5225 0.984375 8.13083 0.984375 7.1475C0.984375 6.1725 1.67604 4.7725 4.97604 3.66416L12.051 1.30583C13.8177 0.714162 15.2927 0.889162 16.201 1.78916C17.1094 2.68916 17.2844 4.1725 16.6927 5.93916L14.3344 13.0142C13.226 16.3308 11.8344 17.0225 10.851 17.0225ZM5.36771 4.85583C3.05104 5.63083 2.22604 6.5475 2.22604 7.1475C2.22604 7.7475 3.05104 8.66416 5.36771 9.43083L7.46771 10.1308C7.65104 10.1892 7.80104 10.3392 7.85938 10.5225L8.55938 12.6225C9.32604 14.9392 10.251 15.7642 10.851 15.7642C11.451 15.7642 12.3677 14.9392 13.1427 12.6225L15.501 5.5475C15.926 4.26416 15.851 3.21416 15.3094 2.6725C14.7677 2.13083 13.7177 2.06416 12.4427 2.48916L5.36771 4.85583Z" fill="#969595"/>
    <path d="M7.4276 10.9974C7.26927 10.9974 7.11094 10.9391 6.98594 10.8141C6.74427 10.5724 6.74427 10.1724 6.98594 9.93073L9.96927 6.93906C10.2109 6.6974 10.6109 6.6974 10.8526 6.93906C11.0943 7.18073 11.0943 7.58073 10.8526 7.8224L7.86927 10.8141C7.7526 10.9391 7.58594 10.9974 7.4276 10.9974Z" fill="#969595"/>
    </svg>
    </>
  )
}

export default LocationIcon