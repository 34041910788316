import ModalSuccess from "Shared/ModalSuccess/ModalSuccess";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { subscribe } from "../../../services/subscriberService";

const RightContent = () => {
  const {t} =useTranslation() 
  const [email, setEmail] = useState("");
  const [showModalSuccess, setShowModalSuccess] = useState({
    display: false,
    success: false,
    alreadyExists: false
  });
  const [showModalFailed, setShowModalFailed] = useState(false);

  const buttonShowModalSuccess = () => { 
    setShowModalSuccess({...showModalSuccess,display: true});
  };
  const buttonhideModalSuccess = () => {
    setShowModalSuccess({display: false , success: false,alreadyExists: false});
  };



  const handleSubmit = async (e) => { 
    e.preventDefault();
    if (!email) { 
      toast.error(t("errorToast"));
      return;
    }
    try{
       await subscribe({email})
       setShowModalSuccess({...showModalSuccess,display: true,success: true});
    }
    catch(err){
      setShowModalSuccess({...showModalSuccess,display: true,alreadyExists: true});    

    }
   
    setEmail("");
  };
  return (
    <>
      <ModalSuccess
        showModalSuccess={showModalSuccess.display}
        hideModalSuccess={buttonhideModalSuccess}
        title={showModalSuccess.alreadyExists? t("modalAlreadySubscribed"):t("modalSubscribed")}
        text={
        t("modalTextSubscribed")
        }
      />
     
      <div className="right-content-footer">
        <h2 className="title nexaBold-font">{t("titleFooterSubscribed")}</h2>
        <div className="main-form-subscribe">
          <form onSubmit={handleSubmit}>
            <div className="form-input-one">
              <label
                htmlFor="exampleFormControlInput1"
                className="form-label d-none"
              ></label>
              <input
                type="email"
                className="form-control"
                id="exampleFormControlInput1"
                placeholder={t("placeholderInputEmailSubscribe")}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <button type="submit" className="btn-main">
                {t("buttonSubscribe")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default RightContent;
