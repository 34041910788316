import { useState } from "react";
import {
  GoogleMap,
  Marker,
  useJsApiLoader,
  InfoWindow
} from "@react-google-maps/api";
import CardProduct from "Components/Ui/CardProduct/CardProduct";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const containerStyle = {
  width: "100%",
  height: "100vh"
};

const center = {
  lat: 30.033,
  lng: 31.033
};

const MapComponent = ({ properties }) => {
  // API FOR GOOGLE MAP
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
    mapIds: ["327f00d9bd231a33"]
  });

  const [selectedProduct, setSelectedProduct] = useState(null);

 
  const handleMarkerClick = (location) => {
    setSelectedProduct(location); // PRODUCT SELECTED
  };
 
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  return isLoaded ? (
    <GoogleMap
      options={{ mapId: "1ad11c0e4789bd3e" }}
      mapContainerStyle={containerStyle}
      center={center}
      zoom={6}
    >
   
      {properties?.map((item) => (
        <Marker
          key={item.id}
          position={{
            lat: parseFloat(item.lat),
            lng: parseFloat(item.lng)
          }}
          onClick={() => handleMarkerClick(item)}
          icon={{
            path: "M 0,0 m -2,0 a 2,2 0 1,0 4,0 a 2,2 0 1,0 -4,0",
            fillColor: "transparent",
            fillOpacity: 0,
            strokeWeight: 0,
            scale: 10
          }}
          label={{
            text: `${item.price}`,
            className:
              selectedProduct && selectedProduct.id === item.id
                ? "marker-label active"
                : "marker-label"
          }}
        />
      ))}

      {selectedProduct && (
        <div className="card-info-window">
          <InfoWindow
            position={{
              lat: parseFloat(selectedProduct.lat),
              lng: parseFloat(selectedProduct.lng)
            }}
            onCloseClick={() => setSelectedProduct(null)}
          >
            <div
              onClick={() => {
                navigate(`/detailsProperties/${selectedProduct.id}`);
              }}
            >
             <CardProduct
                      imageCard={selectedProduct?.photos[0].url}
                      title={ i18n.language === "ar"
                        ? selectedProduct.title_ar
                        : selectedProduct.title_en
                        }
                      textContent={selectedProduct.text}
                      bedNumText={selectedProduct.bedNum}
                      bathtubText={selectedProduct.bathNum}
                      squareText={`${selectedProduct.space} sq.m`}
                      typePrice={"egp"}
                      priceInfo={selectedProduct.price}
                      isReadyMoved={selectedProduct.isReadyToMove}
                      isDeliveryDate={selectedProduct.isDeliveryDate}
                      dateInfo={selectedProduct.date}
                    />
            </div>
          </InfoWindow>
        </div>
      )}
     
    </GoogleMap>
  ) : (
    <></>
  );
};

export default MapComponent;
