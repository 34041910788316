import  { useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../../Firebase/FirebaseConfig";
import { signInWithEmailAndPassword } from "firebase/auth";
import ContainerMedia from "Components/ContainerMedia/ContainerMedia";
import { toast } from "react-toastify";
import { login } from "services/authService";

const Login = () => {
  const [email, setEmail] = useState(""); // EMAIL VALUE
  const [password, setPassword] = useState(""); // PASSWORD VALUE
  const navigate = useNavigate(); // ROUTE PAGE

  // THIS CODE FOR DISBALED BUTTON AND AND ADD LOADER WHEN SEND DATA TO DATBASE
  const [loaderButton, setLoadderButton] = useState(false);
  const [disabledValue, setDisabledValue] = useState(false);
  // ADD LOADER AND DISABLED
  const addLoaderDisabled = () => {
    setLoadderButton(true); // SET LOADER TRUE
    setDisabledValue(true); // SET DISABLED TRUE
  };
  // REMOVE DISABLED AND LOADER
  const removeDisabledLoader = () => {
    setDisabledValue(false); // SET DISABLED FALSE
    setLoadderButton(false); // SET LOADER FALSE
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      addLoaderDisabled(); //ADD LOADER AND DISABLED
      await signInWithEmailAndPassword(auth, email, password);
    //  let res= await login({email,password})
    //   console.log(res);
      navigate("/admin"); //IF SUCCESS LOGIN ROUTE TO PAGE ADMIN
      localStorage.setItem("loggedInUser", email);
      removeDisabledLoader();
      toast.success("Success LogIn");
    } catch (error) {
      // HERE I TAKE MESSAGE OF FIREBASE AND CHNAGE TO MESSAGE "YOU ARE NOT ADMIN"
      // if (error.message === "Firebase: Error (auth/invalid-credential).") {
        toast.error("You Are Not Admin");
      // }
      removeDisabledLoader(); // REMOVE LOADER AND DISABLED
    }
  };

  return (
    <div className="all-padding">
      <ContainerMedia>
        <div className="form-admin form-style">
          <h2 className="title-admin text-center mb-4 text-uppercase">
            Admin Login
          </h2>

          <form onSubmit={handleLogin}>
            {/* ============== START FORM INPUT ================ */}
            <div className="mb-3">
              <label className="from-label">Email:</label>
              <input
                type="email"
                className="form-control"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            {/* ============== END FORM INPUT ================ */}

            {/* ============== START FORM INPUT ================ */}
            <div className="mb-3">
              <label className="from-label">Password:</label>
              <input
                className="form-control"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            {/* ============== END FORM INPUT ================ */}

            <button
              disabled={disabledValue ? "disabled" : ""}
              type="submit"
              className={`btn-main  mt-4 ${disabledValue ? "bttDisabled" : ""}`}
            >
              {loaderButton ? (
                <div className="spinner-border text-light" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                "Login"
              )}
            </button>
          </form>
        </div>
      </ContainerMedia>
    </div>
  );
};

export default Login;
