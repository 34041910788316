import axios from 'axios';


export const fetchProperties = async ({type,lowestPrice,highestPrice,page,rowsPerPage,orderBy="",city,bedNum}) => {
  
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/property?page=${page}`+
      `&limit=${rowsPerPage}&type=${type}&lowestPrice=${lowestPrice}`+
      `&highestPrice=${highestPrice}&sort=${orderBy}`+
      `&bedNum=${bedNum}&cityId=${city}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
  
};


export const fetchPropertyById = async (id) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/property/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const addNewProperty = async (data) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/property`,data,{
      headers: {
        'Content-Type': 'multipart/form-data'
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const deletePropertyById = async (id) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_API_URL}/property/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const updateProperty = async (id,data) => {
  try {
    const response = await axios.patch(`${process.env.REACT_APP_API_URL}/property/${id}`,data);
    return response.data;
  } catch (error) {
    throw error;
  }
}