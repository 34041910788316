import "./ChecboxInput.css";

const CheckboxInput = ({ id, label, isChecked, changeButton }) => {
  return (
    <label
      htmlFor={id}
      className="form-check form-check-input--1 d-flex align-items-center"
      style={{ gap: "2.2rem" }}
    >
      <input
        className="form-check-input"
        type="checkbox"
        id={id}
        checked={isChecked}
        onChange={changeButton}
      />
      <label className="form-check-label" htmlFor={id}>
        {label}
      </label>
    </label>
  );
};

export default CheckboxInput;
