import DropDownMenuFilter from "Shared/DropDownMenuFilter/DropDownMenuFilter";
import React, { useState } from "react";
import bedIcon from "../../../assets/images/iconsFilter/Bed.svg";
import CheckboxInput from "../Checkbox/CheckboxInput";
import { useTranslation } from "react-i18next";

const BedFilter = ({ setBedNum,bedNum }) => {
  // FOR TRANSLATION AR && EN
  const { i18n } = useTranslation();

  // ARRAY OF BEDROOM CHECKBOX ITEMS
  const translations = {
    en: {
      bedroomLabel: (n) => `${n} Bedroom${n > 1 ? 's' : ''}`
    },
    ar: {
      bedroomLabel: (n) => `${n} غرفة نوم`
    }
  };
  
  // Map labels based on the current language
  const checkboxItemsBedrooms = Array.from({ length: 6 }, (_, i) => ({
    id: i + 1,
    label: i18n.language === "ar" ? translations.ar.bedroomLabel(i + 1) : translations.en.bedroomLabel(i + 1),
    value: i + 1
  }));
  // STATE TO TRACK THE SELECTED CHECKBOX
  const [selectedCheckbox, setSelectedCheckbox] = useState(bedNum||null);

  // INITIAL TEXT FOR THE BUTTON DEPENDING ON THE LANGUAGE
  const initialTextButton = i18n.language === "ar" ? "غرف" : "Bedroom";

  //  USE INITIAL TEXT IF NOT SELECTED, IF SELECTED REPLACE INITIAL TEXT
  const checkedLabel =
    selectedCheckbox !== null
      ? checkboxItemsBedrooms.find((item) => item.id === selectedCheckbox)?.label
      : initialTextButton;

  // HANDLE CHECKBOX CHANGE
  const handleCheckboxChange = (id, value) => {
    setSelectedCheckbox(id);
    setBedNum(value);
  };
  return (
    <>
      <div className="drop-filter-one">
        <DropDownMenuFilter
          newClassButton={"button-drop"}
          newClassListDrop={""}
          isShowIconLeftDrop={true}
          iconDrop={bedIcon}
          children={
            <>
              {checkboxItemsBedrooms.map((checkboxItem, index) => (
                <li key={index} className="dropdown-item---1">
                  <CheckboxInput
                    id={checkboxItem.id}
                    label={checkboxItem.label}
                    isChecked={selectedCheckbox === checkboxItem.id}
                    changeButton={() => handleCheckboxChange(checkboxItem.id, checkboxItem.value)}
                  />
                </li>
              ))}
            </>
          }
          checkedLabel={checkedLabel}
        />
      </div>
    </>
  );
};

export default BedFilter;
