import React from "react";
import ContainerMedia from "Components/ContainerMedia/ContainerMedia";
import "./HeaderContentProperties.css";
import HomeIcon from "assets/images/properties/HomeIcon";
import VillaIcon2 from "assets/images/properties/VillaIcon2";
import CompoundIcon1 from "assets/images/properties/CompoundIcon1";
import DuplexIcon1 from "assets/images/properties/DuplexIcon1";
import { useTranslation } from "react-i18next";

const HeaderContentProperties = ({ handleFilterTypeChange, type }) => {
  const { t } = useTranslation();


  return (
    <div className="header-content-properties padding-top-50">
      {/* ================= START CONTAINER ============ */}
      <ContainerMedia>
        {/* ============== START ALL HEADER PROPERTIES ========== */}
        <div className="all-header-properties">
          <h2 className="title nexaBold-font">
            {t("What you’re looking for")}
          </h2>
          <ul className="list-properties d-flex align-items-center gap-3 p-0 m-0">
            <li>
              <div
                className={`link-one-pro text-500 ${type === 'Apartment' ? 'pressed' : ''}`}
                data-type="Apartment"
                onClick={handleFilterTypeChange('Apartment')}
              >
                <HomeIcon /> {t("Apartment")}
              </div>
            </li>
            {/* <li>
              <div
                className={`link-one-pro text-500 ${type === 'Villa' ? 'pressed' : ''}`}
                data-type="Villa"
                onClick={handleFilterTypeChange('Villa')}
              >
                <VillaIcon2 /> {t("Villa")}
              </div>
            </li> */}
            <li>
              <div
                className={`link-one-pro text-500 ${type === 'Challet' ? 'pressed' : ''}`}
                data-type="Challet"
                onClick={handleFilterTypeChange('Chalet')}
              >
                <CompoundIcon1 /> {t("Chalet")}
              </div>
            </li>
           
          </ul>
        </div>
        {/* ============== END ALL HEADER PROPERTIES ========== */}
      </ContainerMedia>
      {/* ================= END CONTAINER ============ */}
    </div>
  );
};

export default HeaderContentProperties;
