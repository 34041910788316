import { useState } from "react";
import { useSearchParams } from "react-router-dom";

function usePropertyFilters() {
  const [searchParams] = useSearchParams();
  const [type, setType] = useState(
    searchParams.get("type") ? searchParams.get("type") : ""
  );
  const [orderBy, setOrderBy] = useState(
    searchParams.get("orderBy") ? searchParams.get("orderBy") : "date,desc"
  );
  const lowestPrice=searchParams.get("lowestPrice") ? searchParams.get("lowestPrice") : ""
  const highestPrice=searchParams.get("highestPrice") ? searchParams.get("highestPrice") : ""
  const bedNum = searchParams.get("bedNum")? searchParams.get("bedNum") : ""
  const city = searchParams.get("city") ? searchParams.get("city") : ""
  

  const handleFilterTypeChange = (item) => () => {
    setType(item);
  };


  const resetProductFilters = () => {
    setType("");
   
  };
  const handleOrderByChange = (item) => {
     setOrderBy(`${item.attr},${item.type}`)
  };
  return {
    type,
    lowestPrice,
    orderBy,
    highestPrice,
    city,
    bedNum,
    handleFilterTypeChange,
    resetProductFilters,
    handleOrderByChange
  };
}

export default usePropertyFilters;
