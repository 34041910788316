import axios from 'axios';


export const contactUs = async (body) => {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/contact-us`,body);
    return response;
};

export const fetchcontactUs = async ({page,rowsPerPage,}) => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/contact-us?page=${page}`+
      `&limit=${rowsPerPage}`);
  return response.data;
};

export const deleteContactById = async ({id}) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_API_URL}/contact-us/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};