import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const MiddleContent = () => {
  const { t } = useTranslation(); // FOR TRANSLATION AR && EN
  const links = [
    { name: t("properties"), url: "/properties" },
    { name: t("about"), url: "/about" },
    { name: t("FAQ"), url: "/faq" },
    { name: t("contact"), url: "/contactUsPage" }
  ];
  return (
    <div className="middle-content-footer">
      {/* ============ START LIST LINKS ============== */}
      <ul className="m-0 p-0 list-links-text">
        {links.map((link) => (
          <li key={link.name}>
            <Link to={link.url} className="link-text">
              {link.name}
            </Link>
          </li>
        ))}
      </ul>
      {/* ============ END LIST LINKS ============== */}
    </div>
  );
};

export default MiddleContent;
