import React from "react";

const ArrowFullScreen = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_440_8622)">
        <path
          d="M1.66797 18.3333L7.5013 12.5M7.5013 12.5H2.62035M7.5013 12.5L7.5013 17.381"
          stroke="#1C274C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M18.332 1.66667L12.4987 7.5M12.4987 7.5L17.3797 7.5M12.4987 7.5V2.61905"
          stroke="#1C274C"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_440_8622">
          <rect width="20" height="20" rx="5" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ArrowFullScreen;
