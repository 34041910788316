import TabsContent from "Components/Ui/TabsContent/TabsContent";
import AboutTab from "./AboutTab/AboutTab";
import Masterplan from "./Masterplan/Masterplan";
import { useTranslation } from "react-i18next";

const TabsContentDetails = ({property}) => {
  // TRANSLATION AR && EN
  const {t}=useTranslation()
  const masterPlanPhoto = property.photos.find(photo => photo.isMasterPlan);
  const tabsData = [
    {
      eventKey: "tab1",
      title: <>{t("AboutDisc")}</>,
      content: <AboutTab property={property} />
    },
    {
      eventKey: "tab2",
      title: <>{t("Masterplan")}</>,
      content: <AboutTab property={property} />
    }
  ];

  return (
    <div className="all-tabs-content-details">
      <TabsContent tabsData={[tabsData[0]]} newClassTabsContent={"tabs-favorite"} />
    </div>
  );
};

export default TabsContentDetails;
