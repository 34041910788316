import { useTranslation } from "react-i18next";
import ArrowIcon from "../../../assets/images/iconsSvg/ArrowIcon";
import "./SectionTitle.css";

const SectionTitle = ({
  title,
  isButtonShow,
  isTextAll,
  newxTextButton,
  functionButton
}) => {
  // FOR TRANSLATION AR && EN

  const { t } = useTranslation();

  return (
    <div className="section-title-content d-flex justify-content-between align-items-center gap-3 flex-wrap">
      <h2 className="title nexaBold-font">{title}</h2>
      {isButtonShow && ( // HERE CHECKED IF BUTTON TRUE SHOW BUTTON ELSE HIDE BUTTON
        <button onClick={functionButton} className="btn-main btn-transparent">
          {isTextAll && t("all")} {newxTextButton} <ArrowIcon />
        </button>
      )}
    </div>
  );
};

export default SectionTitle;
