// @ts-nocheck
import { useState, useEffect } from "react";
import { fetchcontactUs } from "../services/contactService";
import { useNavigate } from "react-router-dom";


const useContacts = ({page=0,rowsPerPage=12}) => {
  const [contacts, setcontacts] = useState(null);
  const [count, setCount] = useState(null);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const getData = async () => {
    try {
      const {results} = await fetchcontactUs({page,rowsPerPage});
      setcontacts(results.data);
      setCount(results.totalCount)
      setLoading(false);

      updateParams()
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  useEffect(() => {

    getData();
  }, [page,rowsPerPage]);


  const updateParams=()=>{
    if(window.location.pathname!=="/"){
      const url = new URL(window.location);
      url.searchParams.set("rowsPerPage", rowsPerPage);
      url.searchParams.set("page", page);
   
      window.history.pushState({}, "", url);
    }
  }


  
  return {contacts, loading, error ,count,getData};
};
export default useContacts;
