import LoaderApi from "Components/LoaderApi/LoaderApi";
import PaginationPage from "Components/Pagination/Pagination";
import CardProduct from "Components/Ui/CardProduct/CardProduct";
import NotDataFound from "Components/Ui/NotDataFound/NotDataFound";
import { dataCard } from "Pages/Properties/Components/AllProductsProperties/Data";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const ProductsCardsResults = ({properties, showMap ,loading}) => {
  
  const [columnClass, setColumnClass] = useState(
    `col-12 col-sm-6 ${showMap ? "col-md-6" : "col-md-3"}`
  );
  const navigate = useNavigate()
  const { i18n ,t} = useTranslation();
  // FUNCTION TO CHECK IF THE SCREEN WIDTH IS LESS THAN OR EQUAL TO 991PX
  const checkMediaQuery = () => {
    return window.matchMedia("(max-width: 991px)").matches;
  };

  useEffect(() => {
    // UPDATE PERPAGE AND   SCREEN WIDTH
    const updateLayout = () => {
      const isSmallScreen = checkMediaQuery();
     
      setColumnClass(
        isSmallScreen
          ? `col-12 col-sm-6 ${showMap ? "col-md-4" : "col-md-4"}`
          : `col-12 col-sm-6 ${showMap ? "col-md-6" : "col-md-3"}`
      );
    };

    //  UPDATE
    updateLayout();

    //  RESIZE EVENTS
    window.addEventListener("resize", updateLayout);

    return () => window.removeEventListener("resize", updateLayout);
  }, [showMap]);


 

  return (
   
    <div className="products-cards-results">
   {loading ? (
        <LoaderApi />
        ) : (
        <div className="row g-3">
          {properties?.length > 0 ? (
            properties.map((item) => {
              return (
              
                  <div
                    key={item.id}
                    onClick={() => {
                      navigate(`/detailsProperties/${item.id}`);
                    }
                    }
                    className={columnClass}
                  >
                    <CardProduct
                      imageCard={item?.photos[0]?.url}
                      title={ i18n.language === "ar"
                        ? item.title_ar
                        : item.title_en
                        }
                      downPayment={item.downPayment}
                      yearsOfInstallments={item.yearsOfInstallments}
                      bedNumText={item.bedNum}
                      bathtubText={item.bathNum}
                      squareText={`${item.space} ${t("squareMeters")}`}
                      typePrice={"egp"}
                      priceInfo={item.price}
                      isReadyMoved={item.isReadyToMove}
                      dateInfo={item.deliveryDate}
                      city={i18n.language === "ar"
                        ? item.city.name_ar
                        : item.city.name_en}
                    />
                  </div>
              );
            })
          ) : (
            <NotDataFound />
          )}
        </div>
      )}
      </div>
  );
};

export default ProductsCardsResults;
