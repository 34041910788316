import markerIconImage from "../../../../../../assets/images/main/Compass.png";

const MarkerIcon = ({ text  ,lat, lng }) => (
  <div className="content-marker d-flex align-items-center justify-content-center flex-column w-100 gap-3">
    <div className="image-marker">
      <img
        src={markerIconImage}
        alt="Marker"
        style={{ width: "24px", height: "24px" }}
      />
    </div>
    <p className="text-marker">{text}</p>
  </div>
);

export default MarkerIcon;
