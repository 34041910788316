import { toast } from "react-toastify";
import { contactUs } from "services/contactService";
export const handleFormSubmit = async (
{  values,
  resetForm,
  buttonShowModalSuccess,
  hideModalDetails,
  propertyId}
) => {
  const body = {
    name: values.name,
    phoneNumber: values.phone,
    message: values.message,
    propertyId
  };

  try {
    await contactUs(body)   
    resetForm(); 
    buttonShowModalSuccess(); 
    hideModalDetails();
  } catch (error) {
    console.log(error);
    toast.error(error.message);
  }
};
