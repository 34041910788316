import { Slider } from "@mui/material";
import { useState } from "react";
import priceIcon from "../../../assets/images/iconsFilter/Dollar.svg";
import DropDownMenuFilter from "Shared/DropDownMenuFilter/DropDownMenuFilter";
import { useTranslation } from "react-i18next";

const minDistance = 1000000;

const PriceFilter = ({lowestPrice,highestPrice, setHighestPrice, setLowestPrice }) => {
  const { i18n } = useTranslation();
  const initialPriceRange = [1000000, 7000000];
  const [value2, setValue2] = useState([Number(lowestPrice)||initialPriceRange[0],Number(highestPrice)||initialPriceRange[1]]);

  const formatNumber = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const handleChange2 = (e, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) return;

    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], 10000000 - minDistance);
        setValue2([clamped, clamped + minDistance]);
        setLowestPrice(clamped);
        setHighestPrice(clamped + minDistance);
      } else {
        const clamped = Math.max(newValue[1], minDistance);
        setValue2([clamped - minDistance, clamped]);
        setLowestPrice(clamped - minDistance);
        setHighestPrice(clamped);
      }
    } else {
      setValue2(newValue);
      setLowestPrice(newValue[0]);
      setHighestPrice(newValue[1]);
    }
  };

  const handleMinInputChange = (event) => {
    const newMinValue = Number(event.target.value.replace(/,/g, ""));
    if (newMinValue < initialPriceRange[0] || newMinValue>initialPriceRange[1]) return;
    setValue2((prevValue) => {
      const clampedMax = Math.max(prevValue[1], newMinValue + minDistance);
      setLowestPrice(newMinValue);
      setHighestPrice(clampedMax);
      return [newMinValue, clampedMax];
    });
  };

  const handleMaxInputChange = (event) => {
    const newMaxValue = Number(event.target.value.replace(/,/g, ""));
    if (newMaxValue < initialPriceRange[0] || newMaxValue > initialPriceRange[1]) return;
    setValue2((prevValue) => {
      const clampedMin = Math.min(prevValue[0], newMaxValue - minDistance);
      setLowestPrice(clampedMin);
      setHighestPrice(newMaxValue);
      return [clampedMin, newMaxValue];
    });
  };

  const formatValue = (value) => `${(value / 1000000).toFixed(0)}M`;

  const getCheckedLabel = () => {
    if (value2[0] === initialPriceRange[0] && value2[1] === initialPriceRange[1]) {
      return i18n.language === "ar" ? "السعر" : "Price";
    } else {
      return `${formatValue(value2[0])} - ${formatValue(value2[1])}`;
    }
  };

  return (
    <div className="drop-filter-one">
      <DropDownMenuFilter
        newClassButton={"button-drop"}
        newClassListDrop={"price-filter-drop"}
        isShowIconLeftDrop={true}
        iconDrop={priceIcon}
        children={
          <div className="price-filter-content">
            <div className="row mb-3 g-3">
              {i18n.language === "ar" ? (
                <>
                  <div className="col-12 col-sm-6">
                    <div className="main-price-input">
                      <label htmlFor="max-input" className="form-label text-500">
                        الى
                      </label>
                      <input
                      disabled
                        id="max-input"
                        type="text"
                        value={formatNumber(value2[1])}
                        onChange={handleMaxInputChange}
                        min={1000000}
                        max={7000000}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <div className="main-price-input">
                      <label htmlFor="min-input" className="form-label text-500">
                        من
                      </label>
                      <input
                      disabled
                        id="min-input"
                        type="text"
                        value={formatNumber(value2[0])}
                        onChange={handleMinInputChange}
                        min={1000000}
                        max={7000000}
                        className="form-control"
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="col-12 col-sm-6">
                    <div className="main-price-input">
                      <label htmlFor="min-input" className="form-label text-500">
                        From
                      </label>
                      <input
                        id="min-input"
                        type="text"
                        disabled
                        value={formatNumber(value2[0])}
                        onChange={handleMinInputChange}
                        min={1000000}
                        max={7000000}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <div className="main-price-input">
                      <label htmlFor="max-input" className="form-label text-500">
                        To
                      </label>
                      <input
                        id="max-input"
                        type="text"
                        disabled
                        value={formatNumber(value2[1])}
                        onChange={handleMaxInputChange}
                        min={1000000}
                        max={7000000}
                        className="form-control"
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
            <Slider
              getAriaLabel={() => "Minimum distance shift"}
              value={value2}
              onChange={handleChange2}
              valueLabelDisplay="auto"
              getAriaValueText={(value) => `${value}`}
              disableSwap
              min={1000000}
              max={7000000}
            />
          </div>
        }
        checkedLabel={getCheckedLabel()}
      />
    </div>
  );
};

export default PriceFilter;
