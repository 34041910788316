import { useEffect, useState } from "react";
import ContainerMedia from "Components/ContainerMedia/ContainerMedia";
import { Link } from "react-router-dom";
import MessagesTable from "./MessagesTable";
import ExportToExcel from "./ExportToExcel";
import PaginationPage from "Components/Pagination/Pagination";
import usePaginationParams from "hooks/usePaginationParams";
import useContacts from "../../../hooks/useContacts";
import { deleteContactById } from "services/contactService";
import { toast } from "react-toastify";

const ContactMessages = () => {
  const { page, handleChangePage ,rowsPerPage}=usePaginationParams()
  const{contacts , loading ,count,getData}= useContacts({rowsPerPage,page});

  const handleDeleteMessage = async(id) => {
    try {
      const res = await deleteContactById({id});
      if (res.id === id) { 
        getData();
        toast.success("Message deleted successfully");
      } else {
        toast.error("Failed to delete Message");
      }
    } catch (error) {
      toast.error("An error occurred while deleting the Message");
      console.error("Error deleting Message:", error);
    }
  };
  const handleContactedChange=(id)=>{

  }

  return (
    <div className="all-padding">
      <ContainerMedia>
        <div className="d-flex justify-content-between align-items-center gap-3 flex-wrap-reverse">
          <h1 className="title-admin">Contact Messages</h1>
          <div className="d-flex align-items-center gap-3 flex-wrap buttons-excel-add">
            {contacts?.length > 0 ? <ExportToExcel messages={contacts} /> : ""}
            <Link to="/admin" className="btn-main">
              Add Properties
            </Link>
          </div>
        </div>
        <div className="messages-container mt-5">
          {loading ? (
            <div className="d-flex justify-content-center align-items-center">
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : count === 0 ? (
            <p className="admin-error-data">No messages available</p>
          ) : (
            <>
              {count> 0 ? (
                <h2 className="admin-error-data num-count-info">
                  Messages : {count}
                </h2>
              ) : (
                ""
              )}
            <MessagesTable
              messages={contacts}
              currentPage={page}
              itemsPerPage={rowsPerPage}
              handleDeleteMessage={handleDeleteMessage}
              handleContactedChange={handleContactedChange}
            />
             
            <PaginationPage
              handleChangePage={handleChangePage}
              page={page}
              rowsPerPage={rowsPerPage}
              count={count}
              />
            </>
          )}
        </div>
      </ContainerMedia>
    </div>
  );
};

export default ContactMessages;
