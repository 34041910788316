import React from 'react'

const HomeIcon = () => {
  return (
    <><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.3346 18.957H1.66797C1.3263 18.957 1.04297 18.6737 1.04297 18.332C1.04297 17.9904 1.3263 17.707 1.66797 17.707H18.3346C18.6763 17.707 18.9596 17.9904 18.9596 18.332C18.9596 18.6737 18.6763 18.957 18.3346 18.957Z" fill="#969595"/>
    <path d="M17.5 18.9596H2.5C2.15833 18.9596 1.875 18.6763 1.875 18.3346L1.875 5.0013C1.875 2.48464 3.31667 1.04297 5.83333 1.04297L14.1667 1.04297C16.6833 1.04297 18.125 2.48464 18.125 5.0013V18.3346C18.125 18.6763 17.8417 18.9596 17.5 18.9596ZM3.125 17.7096H16.875V5.0013C16.875 3.1513 16.0167 2.29297 14.1667 2.29297L5.83333 2.29297C3.98333 2.29297 3.125 3.1513 3.125 5.0013L3.125 17.7096Z" fill="#969595"/>
    <path d="M8.33203 14.375H5.83203C5.49036 14.375 5.20703 14.0917 5.20703 13.75C5.20703 13.4083 5.49036 13.125 5.83203 13.125H8.33203C8.6737 13.125 8.95703 13.4083 8.95703 13.75C8.95703 14.0917 8.6737 14.375 8.33203 14.375Z" fill="#969595"/>
    <path d="M14.168 14.375H11.668C11.3263 14.375 11.043 14.0917 11.043 13.75C11.043 13.4083 11.3263 13.125 11.668 13.125H14.168C14.5096 13.125 14.793 13.4083 14.793 13.75C14.793 14.0917 14.5096 14.375 14.168 14.375Z" fill="#969595"/>
    <path d="M8.33203 10.625H5.83203C5.49036 10.625 5.20703 10.3417 5.20703 10C5.20703 9.65833 5.49036 9.375 5.83203 9.375H8.33203C8.6737 9.375 8.95703 9.65833 8.95703 10C8.95703 10.3417 8.6737 10.625 8.33203 10.625Z" fill="#969595"/>
    <path d="M14.168 10.625H11.668C11.3263 10.625 11.043 10.3417 11.043 10C11.043 9.65833 11.3263 9.375 11.668 9.375H14.168C14.5096 9.375 14.793 9.65833 14.793 10C14.793 10.3417 14.5096 10.625 14.168 10.625Z" fill="#969595"/>
    <path d="M8.33203 6.875H5.83203C5.49036 6.875 5.20703 6.59167 5.20703 6.25C5.20703 5.90833 5.49036 5.625 5.83203 5.625H8.33203C8.6737 5.625 8.95703 5.90833 8.95703 6.25C8.95703 6.59167 8.6737 6.875 8.33203 6.875Z" fill="#969595"/>
    <path d="M14.168 6.875H11.668C11.3263 6.875 11.043 6.59167 11.043 6.25C11.043 5.90833 11.3263 5.625 11.668 5.625H14.168C14.5096 5.625 14.793 5.90833 14.793 6.25C14.793 6.59167 14.5096 6.875 14.168 6.875Z" fill="#969595"/>
    </svg>
    </>
  )
}

export default HomeIcon