import React from "react";
import ImageContact from "./Components/ImageContact";
import FormContact from "./Components/FormContact";
import "./ContactUs.css";
import { useTranslation } from "react-i18next";
const ContactUs = () => {
  // TRANSLATION AR && EN
  const { t } = useTranslation();
  return (
    <div className="contact-details padding-top-50">
      {/* ============= START ROW ============= */}
      <div className="row g-4">
        {/* ============== START COL ============ */}
        <div className="col-12 col-md-6">
          <ImageContact />
        </div>
        {/* ============== END COL ============ */}
        {/* ============== START COL ============ */}
        <div className="col-12 col-md-6">
          <FormContact titleTop={t("headerFormTitle")} />
        </div>
        {/* ============== END COL ============ */}
      </div>
      {/* ============= END ROW ============= */}
    </div>
  );
};

export default ContactUs;
