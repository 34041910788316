import React from 'react'

const Bathtub = () => {
  return (
    <><svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 9.22178C2 9.1706 2 9.14501 2.00141 9.12341C2.02313 8.7893 2.2893 8.52313 2.62341 8.50141C2.64501 8.5 2.6706 8.5 2.72178 8.5H13.2782C13.3294 8.5 13.355 8.5 13.3766 8.50141C13.7107 8.52313 13.9769 8.7893 13.9986 9.12341C14 9.14501 14 9.1706 14 9.22178C14 9.48557 14 9.61746 13.9906 9.7651C13.8515 11.957 11.9886 13.8911 9.80355 14.1124C9.65636 14.1273 9.5705 14.1306 9.39878 14.137C8.90936 14.1554 8.43288 14.1667 8 14.1667C7.56712 14.1667 7.09064 14.1554 6.60122 14.137C6.4295 14.1306 6.34364 14.1273 6.19645 14.1124C4.01136 13.8911 2.14848 11.957 2.00937 9.7651C2 9.61746 2 9.48557 2 9.22178Z" stroke="#212121"/>
    <path d="M3.9987 13.832L3.33203 15.1654" stroke="#212121" stroke-linecap="round"/>
    <path d="M12.0013 13.832L12.668 15.1654" stroke="#212121" stroke-width="1.5" stroke-linecap="round"/>
    <path d="M1.33203 8.5H14.6654" stroke="#212121" stroke-linecap="round"/>
    <path d="M1.5 9.16536C1.5 9.44151 1.72386 9.66536 2 9.66536C2.27614 9.66536 2.5 9.44151 2.5 9.16536H1.5ZM5.06649 2.83159L5.53073 2.64589L5.53073 2.64589L5.06649 2.83159ZM5.31924 3.46345L5.53809 3.91301L5.31924 3.46345ZM4.24141 4.62691L4.70467 4.81504L4.24141 4.62691ZM7.05134 3.41725L6.85491 3.87705L6.85491 3.87705L7.05134 3.41725ZM4.23039 6.32641L3.7652 6.50971C3.81481 6.63559 3.91332 6.73601 4.03823 6.78801C4.16315 6.84001 4.30382 6.83916 4.4281 6.78566L4.23039 6.32641ZM8.20423 4.61569L8.40194 5.07494C8.65 4.96815 8.76843 4.68366 8.66942 4.43239L8.20423 4.61569ZM2.5 9.16536V3.42214H1.5V9.16536H2.5ZM3.59011 2.33203C4.03586 2.33203 4.4367 2.60342 4.60225 3.01728L5.53073 2.64589C5.21332 1.85237 4.44477 1.33203 3.59011 1.33203V2.33203ZM2.5 3.42214C2.5 2.82009 2.98806 2.33203 3.59011 2.33203V1.33203C2.43577 1.33203 1.5 2.26781 1.5 3.42214H2.5ZM4.60225 3.01728L4.855 3.64915L5.78347 3.27776L5.53073 2.64589L4.60225 3.01728ZM4.69558 6.14311C4.53445 5.73417 4.52426 5.25931 4.70467 4.81504L3.77815 4.43879C3.49795 5.1288 3.51306 5.8698 3.7652 6.50971L4.69558 6.14311ZM8.00652 4.15643L4.03269 5.86715L4.4281 6.78566L8.40194 5.07494L8.00652 4.15643ZM6.85491 3.87705C7.26929 4.05408 7.57801 4.3903 7.73904 4.79898L8.66942 4.43239C8.41718 3.79222 7.92465 3.24663 7.24777 2.95745L6.85491 3.87705ZM4.70467 4.81504C4.87412 4.39777 5.17885 4.0879 5.53809 3.91301L5.10038 3.01389C4.52225 3.29534 4.04169 3.78984 3.77815 4.43879L4.70467 4.81504ZM5.53809 3.91301C5.94302 3.71589 6.41671 3.68984 6.85491 3.87705L7.24777 2.95745C6.52924 2.65049 5.75249 2.69644 5.10038 3.01389L5.53809 3.91301Z" fill="#212121"/>
    </svg>
    </>
  )
}

export default Bathtub