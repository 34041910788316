import FilterCategory from "Components/Ui/FilterCategory/FilterCategory";
import ContainerMedia from "../../../../Components/ContainerMedia/ContainerMedia";
import imageBanner1 from "../../../../assets/images/main/slider.png";
import "./SliderBanner.css";
import { useTranslation } from "react-i18next";

const SliderBanner = ({showSearchResults}) => {
  // TRANSLATION AR && EN
  const {t} =useTranslation()
  return (
    <div className="banner-main-area">
      {/* ========= START BANNER ONE ======== */}
      <div
        className="banner-one section-padding bg-image"
        style={{ backgroundImage: `url(${imageBanner1})` }}
      >
        {/* ======== START INFO BANNER ========= */}
        <div className="info-banner position-relative z-1">
          {/* ======= START CONTAINER =========== */}
          <ContainerMedia>
            {/* ========= START CONTENT SLIDE ========== */}
            <div className="content-slide">
              <p className="text-top">{t("slidertextTop")}</p>
              <h1 className="title">
              {t("sliderTitle")}
              </h1>
              <p className="text">
              {t("sliderTextContent")}
              </p>
            <FilterCategory showSearchResults={showSearchResults}/>
            </div>
            {/* ========= END CONTENT SLIDE ============ */}
          </ContainerMedia>
          {/* ======== END CONTAINER =========== */}
        </div>
        {/* ======== END INFO BANNER ========== */}
      </div>
      {/* ======== END BANNER ONE ========= */}
    </div>
  );
};

export default SliderBanner;
