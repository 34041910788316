import ContainerMedia from "Components/ContainerMedia/ContainerMedia";
import SectionTitle from "Components/Ui/SectionTitle/SectionTitle";
import React from "react";
import { useNavigate } from "react-router-dom";
import CardsProductsInfo from "./CardsProductsInfo";
import { useTranslation } from "react-i18next";

const AllProductsProperties = ({properties,loading }) => {
  // ROUTE NAVIGATE PAGE
  const navigate = useNavigate();
  // TRANSLATION AR && EN
  const { t } = useTranslation();
  return (
    <div className="all-products-properties padding-top-30">
      {/* ================ START CONTAINER =============== */}
      <ContainerMedia>
        {/* ================= START SECTION TITLE =============== */}
        <SectionTitle
          title={t("Latestproperties")}
          isButtonShow={true}
          isTextAll={true}
          newxTextButton={false}
          functionButton={() => {
            navigate("/results");
          }}
        />
        {/* ================= END SECTION TITLE =============== */}

        {/* =============== START ALL CARDS PRODUCTS ============ */}
        <div className="all-cards-products pt-1">
          <CardsProductsInfo properties={properties} loading={loading} />
        </div>
        {/* =============== END ALL CARDS PRODUCTS ============ */}
      </ContainerMedia>
      {/* ================= END CONTAINER ================ */}
    </div>
  );
};

export default AllProductsProperties;
