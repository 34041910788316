import { useState, useEffect } from "react";
import { fetchCities } from "../services/cityService";

const useCities = () => {
  const [cities, setCities] = useState([]);


  useEffect(() => {
    const getCities = async () => {
      try {
        const data = await fetchCities();
        setCities(data);
      } catch (error) {
        console.log(error);
      }
    };

    getCities();
  }, []);

  return { cities};
};

export default useCities;
