// @ts-nocheck
import { useRef, useState } from "react";
import ContainerMedia from "Components/ContainerMedia/ContainerMedia";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "../Admin/Admin.css";
import { useAuth } from "Context/AuthProvider";
import { addNewProperty } from "services/propertyService";
import useCities from "../../hooks/useCities";
import useDevelopers from "../../hooks/useDevelopers";

const Admin = () => {
  const { cities } = useCities();
  const { developers } = useDevelopers();
  const fileInputRef = useRef(null);

  const [formData, setFormData] = useState({
    description_ar: "",
    description_en: "",
    images: [],
    location: { lat: "", lng: "" },
    number_of_bathrooms: "",
    number_of_rooms: "",
    yearsOfInstallments: "",
    downPayment: "",
    price: "",
    property_name_ar: "",
    property_name_en: "",
    square_m: "",
    type: "",
    amenities: {
      Furnished: false,
      Garden: false,
      Gym: false,
      Parking: false,
      Pool: false,
      ready_to_move: false,
    },
    delivery_date: "",
    city: "",
    developer: "",
    property_status: ""
  });

  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;
// console.log(value);
    if (type === "checkbox") {
      setFormData({
        ...formData,
        amenities: {
          ...formData.amenities,
          [name]: checked,
        },
      });
    } else if (name === "location_lat" || name === "location_lng") {
      setFormData({
        ...formData,
        location: {
          ...formData.location,
          [name.split("_")[1]]: value,
        },
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handlePropertyImagesChange = (e) => {
    setFormData({
      ...formData,
      images: e.target.files,
    });
  };

  const [loaderButton, setLoaderButton] = useState(false);
  const [disabledValue, setDisabledValue] = useState(false);

  const addLoaderDisabled = () => {
    setLoaderButton(true);
    setDisabledValue(true);
  };

  const removeDisabledLoader = () => {
    setDisabledValue(false);
    setLoaderButton(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const {
      description_ar,
      description_en,
      images,
      location,
      number_of_bathrooms,
      number_of_rooms,
      price,
      property_name_ar,
      property_name_en,
      square_m,
      amenities,
      delivery_date,
      city,
      type,
      property_status,
      developer,
      yearsOfInstallments,
      downPayment,
    } = formData;
  
    try {
      addLoaderDisabled();

      let body = new FormData();
      body.append("title_en", property_name_en);
      body.append("title_ar", property_name_ar);
      body.append("type", type);
      body.append("price", price);
      body.append("lat", location.lat);
      body.append("lng", location.lng);
      body.append("status", property_status);

      for (const image of images) {
        body.append("images", image);
      }
      body.append("bedNum", number_of_rooms);
      body.append("space", square_m);
      body.append("bathNum", number_of_bathrooms);
      body.append("isReadyToMove", amenities.ready_to_move);
      body.append("deliveryDate", delivery_date);
      body.append("text_en", description_en);
      body.append("text_ar", description_ar);
      body.append("cityId", city); 
      body.append("developerId", developer); 
      body.append("has_pool", amenities.Pool);
      body.append("has_gym", amenities.Gym);
      body.append("has_garden", amenities.Garden);
      body.append("furnished", amenities.Furnished);
      body.append("parking", amenities.Parking);
      body.append("yearsOfInstallments", yearsOfInstallments);
      body.append("downPayment", downPayment);
      await addNewProperty(body);
      toast.success("Property added successfully");
      clearForm();
      removeDisabledLoader();
    } catch (error) {
      toast.error(error.response.data.error);
      removeDisabledLoader();
    }
  };

  const clearForm = () => {
    setFormData({
      description_ar: "",
      description_en: "",
      images: [],
      location: { lat: "", lng: "" },
      masterplan: null,
      number_of_bathrooms: "",
      number_of_rooms: "",
      price: "",
      property_name_ar: "",
      property_name_en: "",
      square_m: "",
      amenities: {
        Furnished: false,
        Garden: false,
        Gym: false,
        Parking: false,
        Pool: false,
        ready_to_move: false,
      },
      delivery_date: "",
      city: "",
      type: "",
      property_status: "",
      developer: "",
      yearsOfInstallments: "",
    downPayment: "",
    });
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const { logout } = useAuth();

  const handleLogout = () => {
    logout();
  };

  return (
    <div className="admin-dash all-padding">
      <ContainerMedia>
        <div className="buttons-top-admin d-flex justify-content-between gap-3 align-items-center flex-wrap-reverse mb-4">
          <div className="main-buttons-top d-flex align-items-center gap-3 flex-wrap">
            <Link to="/admin/propertyManagement" className="btn-main">
              View Properties
            </Link>
            <Link to="/admin/contactMessages" className="btn-main">
              Contact Messages
            </Link>
            <Link to="/admin/cityManagement" className="btn-main">
              Add City
            </Link>
            <Link to="/admin/developerManagement" className="btn-main">
              Add Developer
            </Link>
          </div>
          <button onClick={handleLogout} className="btn btn-danger">
            Logout
          </button>
        </div>

        <div className="mb-3">
          <h2 className="title-admin">Add New Property</h2>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="property_name_ar" className="form-label mb-2">
              Property Name (Arabic)
            </label>
            <input
              type="text"
              className="form-control"
              id="property_name_ar"
              name="property_name_ar"
              placeholder="Property Name (Arabic)"
              value={formData.property_name_ar}
              onChange={handleChange}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="property_name_en" className="form-label mb-2">
              Property Name (English)
            </label>
            <input
              type="text"
              className="form-control"
              id="property_name_en"
              name="property_name_en"
              placeholder="Property Name (English)"
              value={formData.property_name_en}
              onChange={handleChange}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="property_type" className="form-label mb-2">
              Property Type
            </label>
            <select
              className="form-select custom-select-arrow"
              id="property_type"
              name="type"
              value={formData.type}
              onChange={handleChange}
              style={{ paddingRight: "2rem", backgroundImage: "none" ,  appearance: "auto"}}
            >
              <option value="">Select Property Type</option>
              <option value="Apartment">Apartment</option>
              <option value="Chalet">Chalet</option>
              {/* <option value="Villa">Villa</option> */}
            </select>
          </div>

        <div className="mb-3">
          <label htmlFor="delivery_date" className="form-label mb-2">
            Delivery Date (in years)
          </label>
          <input
            type="number"
            onWheel={(e) => e.target.blur()}

            className="form-control"
            id="delivery_date"
            name="delivery_date"
            placeholder="Delivery Date (in years)"
            value={formData.delivery_date}
            onChange={handleChange}
          />
        </div>

          <div className="mb-3">
              <label htmlFor="city" className="form-label mb-2">
                City
              </label>
              <select
                className="form-select custom-select-arrow"
                id="city"
                name="city"
                value={formData.city}
                onChange={handleChange}
                style={{ paddingRight: "2rem", backgroundImage: "none" ,  appearance: "auto"}}
              >
                <option value="">Select City</option>
                {cities.map((city) => (
                  <option key={city.id} value={city.id}>
                    {city.name_en} / {city.name_ar}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-3">
              <label htmlFor="developer" className="form-label mb-2">
                Developer
              </label>
              <select
                className="form-select custom-select-arrow"
                id="developer"
                name="developer"
                value={formData.developer}
                onChange={handleChange}
                style={{ paddingRight: "2rem", backgroundImage: "none", appearance: "auto" }}
              >
                <option value="">Select Developer</option>
                {developers.map((developer) => (
                  <option key={developer.id} value={developer.id}>
                    {developer.name_en} / {developer.name_ar}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-3">
              <label htmlFor="property_status" className="form-label mb-2">
                Property Status
              </label>
              <select
                
                className="form-select"
                id="property_status"
                name="property_status"
                value={formData.property_status}
                onChange={handleChange}
                style={{ paddingRight: "2rem", backgroundImage: "none" ,  appearance: "auto"}}
              >
                <option value="">Select Property Status</option>
                <option value="fully-finished">Fully-Finished</option>
                <option value="semi-finished">Semi-Finished</option>
                <option value="core and shell">Core and Shell</option>
              </select>
            </div>
            
          <div className="mb-3">
            <label htmlFor="description_ar" className="form-label mb-2">
              Description (Arabic)
            </label>
            <input
              type="text"
              className="form-control"
              id="description_ar"
              name="description_ar"
              placeholder="Description (Arabic)"
              value={formData.description_ar}
              onChange={handleChange}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="description_en" className="form-label mb-2">
              Description (English)
            </label>
            <input
              type="text"
              className="form-control"
              id="description_en"
              name="description_en"
              placeholder="Description (English)"
              value={formData.description_en}
              onChange={handleChange}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="location_lat" className="form-label mb-2">
              Location Latitude
            </label>
            <input
              type="text"
              className="form-control"
              id="location_lat"
              name="location_lat"
              placeholder="Latitude"
              value={formData.location.lat}
              onChange={handleChange}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="location_lng" className="form-label mb-2">
              Location Longitude
            </label>
            <input
              type="text"
              className="form-control"
              id="location_lng"
              name="location_lng"
              placeholder="Longitude"
              value={formData.location.lng}
              onChange={handleChange}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="property_images" className="form-label mb-2">
              Property Images
            </label>
            <input
              type="file"
              className="form-control"
              id="property_images"
              name="property_images"
              multiple
              onChange={handlePropertyImagesChange}
              ref={fileInputRef}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="number_of_rooms" className="form-label mb-2">
              Number of Rooms
            </label>
            <input
              type="number"
              onWheel={(e) => e.target.blur()}
              className="form-control"
              id="number_of_rooms"
              name="number_of_rooms"
              placeholder="Number of Rooms"
              value={formData.number_of_rooms}
              onChange={handleChange}
            />
          </div>
       
          <div className="mb-3">
            <label htmlFor="number_of_bathrooms" className="form-label mb-2">
              Number of Bathrooms
            </label>
            <input
              type="number"
              onWheel={(e) => e.target.blur()}
              className="form-control"
              id="number_of_bathrooms"
              name="number_of_bathrooms"
              placeholder="Number of Bathrooms"
              value={formData.number_of_bathrooms}
              onChange={handleChange}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="square_m" className="form-label mb-2">
              Square Meters
            </label>
            <input
              type="number"
              onWheel={(e) => e.target.blur()}
              className="form-control"
              id="square_m"
              name="square_m"
              placeholder="Square Meters"
              value={formData.square_m}
              onChange={handleChange}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="price" className="form-label mb-2">
              Price
            </label>
            <input
              type="number"
              onWheel={(e) => e.target.blur()}
              className="form-control"
              id="price"
              name="price"
              placeholder="Price"
              value={formData.price}
              onChange={handleChange}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="yearsOfInstallments" className="form-label mb-2">
               Years of Installment
            </label>
            <input
              type="number"
              onWheel={(e) => e.target.blur()}
              className="form-control"
              id="yearsOfInstallments"
              name="yearsOfInstallments"
              placeholder="Years of Installment"
              value={formData.yearsOfInstallments}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="downPayment" className="form-label mb-2">
              Down Payment
            </label>
            <input
              type="number"
              onWheel={(e) => e.target.blur()}
              className="form-control"
              id="downPayment"
              name="downPayment"
              placeholder=" Down Payment"
              value={formData.downPayment}
              onChange={handleChange}
            />
          </div>
          <div className="all-check-inputs-form me-3 d-flex alin-items-center flex-wrap gap-3">
            <div className="mb-3">
              <div className="form-check">
                <input
                  type="checkbox"
                  id="ready_to_move"
                  name="ready_to_move"
                  checked={formData.amenities.ready_to_move}
                  onChange={handleChange}
                  className="form-check-input"
                />
                <label htmlFor="ready_to_move">Ready to Move</label>
              </div>
            </div>
          </div>

          <div className="mb-3">
            <label htmlFor="amenities" className="form-label mb-2">
              Amenities
            </label>
            <div className="all-check-inputs-form me-3 d-flex alin-items-center flex-wrap gap-3">
              <div className="form-check">
                <input
                  type="checkbox"
                  id="Furnished"
                  name="Furnished"
                  checked={formData.amenities.Furnished}
                  onChange={handleChange}
                  className="form-check-input"
                />
                <label htmlFor="Furnished">Furnished</label>
              </div>
              <div className="form-check">
                <input
                  type="checkbox"
                  id="Garden"
                  name="Garden"
                  checked={formData.amenities.Garden}
                  onChange={handleChange}
                  className="form-check-input"
                />
                <label htmlFor="Garden">Garden</label>
              </div>
              <div className="form-check">
                <input
                  type="checkbox"
                  id="Gym"
                  name="Gym"
                  checked={formData.amenities.Gym}
                  onChange={handleChange}
                  className="form-check-input"
                />
                <label htmlFor="Gym">Gym</label>
              </div>
              <div className="form-check">
                <input
                  type="checkbox"
                  id="Parking"
                  name="Parking"
                  checked={formData.amenities.Parking}
                  onChange={handleChange}
                  className="form-check-input"
                />
                <label htmlFor="Parking">Parking</label>
              </div>
              <div className="form-check">
                <input
                  type="checkbox"
                  id="Pool"
                  name="Pool"
                  checked={formData.amenities.Pool}
                  onChange={handleChange}
                  className="form-check-input"
                />
                <label htmlFor="Pool">Pool</label>
              </div>
            </div>
          </div>
          <button
            type="submit"
            className="btn btn-primary"
            disabled={disabledValue}
          >
            {loaderButton && (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            Add Property
          </button>
        </form>
      </ContainerMedia>
    </div>
  );
};

export default Admin;

