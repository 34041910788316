import logo from "../../assets/images/logo/logoFooter.svg";
import LogoInfo from "./LogoInfo";

const LogoFooter = () => {
  return (
    <>
      <LogoInfo logo={logo} />
    </>
  );
};

export default LogoFooter;
