import React from 'react'

const DuplexIcon1 = () => {
  return (
    <><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.4156 18.9566H3.39896C2.09062 18.9566 1.01563 17.9066 1.01563 16.6066L1.01563 4.23995C1.01563 2.96495 1.49062 1.97328 2.35729 1.43162C3.23229 0.881618 4.36563 0.898284 5.54063 1.48162L9.24062 3.29828C10.249 3.79828 11.0406 5.05662 11.0406 6.17328L11.0406 18.3316C11.0406 18.6733 10.7573 18.9566 10.4156 18.9566ZM3.79896 2.28995C3.50729 2.28995 3.24062 2.35662 3.01562 2.49828C2.53229 2.79828 2.26562 3.42328 2.26562 4.23995L2.26562 16.6066C2.26562 17.215 2.77396 17.7066 3.39896 17.7066H9.79062L9.79062 6.17328C9.79062 5.53162 9.27396 4.70662 8.68229 4.42328L4.98229 2.60662C4.55729 2.39828 4.15729 2.28995 3.79896 2.28995Z" fill="#969595"/>
    <path d="M15.6763 18.9564H10.418C10.0763 18.9564 9.79297 18.6731 9.79297 18.3314L9.79297 8.68144C9.79297 8.48977 9.8763 8.31477 10.0263 8.19811C10.1763 8.08144 10.3596 8.03144 10.5513 8.07311L10.943 8.15644L16.393 9.37311C18.0513 9.73977 18.8846 10.7148 18.9346 12.3731C18.943 12.3731 18.943 12.4564 18.943 12.5481V15.6981C18.9346 17.8314 17.8096 18.9564 15.6763 18.9564ZM11.043 17.7064H15.6763C17.143 17.7064 17.6846 17.1648 17.6846 15.6981V12.4814C17.643 11.3398 17.2096 10.8314 16.118 10.5898L11.043 9.45644V17.7064Z" fill="#969595"/>
    <path d="M7.4737 8.125H4.58203C4.24036 8.125 3.95703 7.84167 3.95703 7.5C3.95703 7.15833 4.24036 6.875 4.58203 6.875H7.4737C7.81537 6.875 8.0987 7.15833 8.0987 7.5C8.0987 7.84167 7.8237 8.125 7.4737 8.125Z" fill="#969595"/>
    <path d="M7.4737 11.457H4.58203C4.24036 11.457 3.95703 11.1737 3.95703 10.832C3.95703 10.4904 4.24036 10.207 4.58203 10.207H7.4737C7.81537 10.207 8.0987 10.4904 8.0987 10.832C8.0987 11.1737 7.8237 11.457 7.4737 11.457Z" fill="#969595"/>
    <path d="M12.6836 14.7901C11.3086 14.7901 10.1836 13.6651 10.1836 12.2901V8.7651C10.1836 8.57344 10.2669 8.39844 10.4169 8.27344C10.5669 8.14844 10.7586 8.10677 10.9419 8.14844L14.6919 8.9901C14.9753 9.05677 15.1836 9.30677 15.1836 9.59844V12.2818C15.1836 13.6651 14.0669 14.7901 12.6836 14.7901ZM11.4336 9.54844V12.2901C11.4336 12.9818 11.9919 13.5401 12.6836 13.5401C13.3753 13.5401 13.9336 12.9818 13.9336 12.2901V10.1068L11.4336 9.54844Z" fill="#969595"/>
    <path d="M16.4336 14.7906C15.0586 14.7906 13.9336 13.6656 13.9336 12.2906V9.60722C13.9336 9.41555 14.0169 9.24055 14.1669 9.12389C14.3169 9.00722 14.5086 8.95722 14.6919 8.99889L16.3836 9.37389C18.0419 9.74055 18.8753 10.7156 18.9253 12.3739C18.9253 12.3906 18.9253 12.4072 18.9253 12.4239C18.8586 13.7489 17.7669 14.7906 16.4336 14.7906ZM15.1836 10.3822V12.2906C15.1836 12.9822 15.7419 13.5406 16.4336 13.5406C17.0919 13.5406 17.6336 13.0322 17.6753 12.3822C17.6336 11.3322 17.2003 10.8322 16.1169 10.5906L15.1836 10.3822Z" fill="#969595"/>
    </svg></>
  )
}

export default DuplexIcon1