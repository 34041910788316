// @ts-nocheck
import { useState } from "react";
import ContainerMedia from "Components/ContainerMedia/ContainerMedia";
import { toast } from "react-toastify";
import { addNewDeveloper } from "services/developerService";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "../Admin/Admin.css";

const DeveloperManagement = () => {
  const [formData, setFormData] = useState({
    name_ar: "",
    name_en: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await addNewDeveloper(formData);
      toast.success("Developer added successfully");
      clearForm();
    } catch (error) {
      toast.error(error.response.data.error);
    }
  };

  const clearForm = () => {
    setFormData({
      name_ar: "",
      name_en: "",
    });
  };

  return (
    <div className="admin-dash all-padding">
      <ContainerMedia>
        {/* ========== START BUTTONS ============== */}
        <div className="buttons-top-admin d-flex justify-content-between  gap-3 align-items-center flex-wrap-reverse mb-4">
          <div className="main-buttons-top d-flex align-items-center gap-3 flex-wrap">
            <Link to="/admin/propertyManagement" className="btn-main">
              View Properties
            </Link>
            <Link to="/admin/contactMessages" className="btn-main">
              Contact Messages
            </Link>
            <Link to="/admin/cityManagement" className="btn-main">
              Add City
            </Link>
            <Link to="/admin/developerManagement" className="btn-main">
              Add Developer
            </Link>
          </div>
        </div>
        {/* ========== END BUTTONS ============== */}

        <div className="mb-3">
          <h2 className="title-admin">Add New Developer</h2>
        </div>

        <form onSubmit={handleSubmit}>
          {/* ============ START INPUT FORM ============= */}
          <div className="mb-3">
            <label htmlFor="name_ar" className="form-label mb-2">
              Developer Name (Arabic)
            </label>
            <input
              type="text"
              className="form-control"
              id="name_ar"
              name="name_ar"
              placeholder="Developer Name (Arabic)"
              value={formData.name_ar}
              onChange={handleChange}
            />
          </div>
          {/* ============ END INPUT FORM ============= */}
          {/* ============ START INPUT FORM ============= */}
          <div className="mb-3">
            <label htmlFor="name_en" className="form-label mb-2">
              Developer Name (English)
            </label>
            <input
              type="text"
              className="form-control"
              id="name_en"
              name="name_en"
              placeholder="Developer Name (English)"
              value={formData.name_en}
              onChange={handleChange}
            />
          </div>
          {/* ============ END INPUT FORM ============= */}

          <button type="submit" className="btn-main">
            Add Developer
          </button>
        </form>
      </ContainerMedia>
    </div>
  );
};

export default DeveloperManagement;
