import CustomModal from "Components/CustomModal/CustomModal";
import IconSuccess from "./IconSuccess";

import "./ModalSuccess.css";
import { useTranslation } from "react-i18next";
const ModalSuccess = ({ showModalSuccess, hideModalSuccess, title, text }) => {
  // TRANSLATION AR && EN
  const { t } = useTranslation();
  return (
    <CustomModal
      show={showModalSuccess}
      onHide={hideModalSuccess}
      title={""}
      newClass={"modal-success"}
    >
      {/* ============= START CONTENT INFO MODAL SUCCESS ============= */}
      <div className="content-info-modal-success">
        <div className="icon-success">
          <IconSuccess />
        </div>
        <h2 className="title nexaBold-font">{title}</h2>
        <p className="text">{text}</p>
        <button onClick={hideModalSuccess} className="done btn-main w-100">
          {t("done")}
        </button>
      </div>
      {/* ============= END CONTENT INFO MODAL SUCCESS ============= */}
    </CustomModal>
  );
};

export default ModalSuccess;
