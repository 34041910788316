import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import React from "react";
import HeaderFilter from "./Components/HeaderFilter/HeaderFilter";
import HeaderContentProperties from "./Components/HeaderContentProperties/HeaderContentProperties";
import AllProductsProperties from "./Components/AllProductsProperties/AllProductsProperties";
import { useTranslation } from "react-i18next";
import useProperties from "hooks/useProperties";
import usePropertyFilters from "hooks/usePropertyFilters";
import usePaginationParams from "hooks/usePaginationParams";
import PaginationPage from "Components/Pagination/Pagination";

const Properties = () => {
  // TRANSLATION AR && EN
  const {t} =useTranslation()
  const{type,handleFilterTypeChange} = usePropertyFilters();
  const { page, handleChangePage ,rowsPerPage}=usePaginationParams()
  const{ properties, loading, error,count,showSearchResults }= useProperties({type,rowsPerPage,page})
  return (
    <>
      <HelmetInfo titlePage={t("properties")} />
      <header>
        <HeaderFilter showSearchResults={showSearchResults} />
        <HeaderContentProperties type={type} handleFilterTypeChange={handleFilterTypeChange}/>
      </header>
      <main>
      <AllProductsProperties properties={properties} loading={loading}/>
      <PaginationPage
           handleChangePage={handleChangePage}
          page={page}
          rowsPerPage={rowsPerPage}
          count={count}
          />
      </main>
    </>
  );
};

export default Properties;
