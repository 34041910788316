import ContainerMedia from "Components/ContainerMedia/ContainerMedia";
import React from "react";
import MainAboutContent from "./MainAboutContent";
import image1 from "../../../../assets/images/about/02.png";
import image2 from "../../../../assets/images/about/03.png";

import "./MainAbout.css";
import { useTranslation } from "react-i18next";

const MainAbout = () => {
  // FOR TRANSLATION AR && EN
  const {t}=useTranslation()
  // ARRAY OF CONTENT MAIN ABOUT OCNTENT 2
  const items = [
    t("ourMissionText1"),
    t("ourMissionText2"),
    t("ourMissionText3")
  ];
  return (
    <div className="main-about padding-top-50">
      {/* ============== START CONTAINER ============= */}
      <ContainerMedia>
        {/* ================= START ALL MAIN ABOUT ============ */}
        <div className="all-main-about">
          <MainAboutContent
            newClassRow={"row-info"}
            image={image1}
            title={t("ourVision")}
            text={
          t("contentOurVision")
            }
            items={false}
          />
          <MainAboutContent
            newClassRow={"padding-top-50 flex-row-reverse"}
            image={image2}
            title={t("ourMission")}
            text={false}
            items={items}
          />
        </div>
        {/* ================= END ALL MAIN ABOUT ============ */}
      </ContainerMedia>
      {/* ============== END CONTAINER ============= */}
    </div>
  );
};

export default MainAbout;
