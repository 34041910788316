import BedIcons from "assets/images/products/icons/BedIcons";
import image from "../../../../assets/images/main/01.png";
import Bathtub from "assets/images/products/icons/Bathtub";
import SquareIcon from "assets/images/products/icons/SquareIcon";
import { useTranslation } from "react-i18next";
const LeftModalContent = ({property}) => {
    // THIS TO ADD "," IN NUMBER
    const { i18n,t } = useTranslation();

    const formatNumber = num => {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };
  
  return (
    <div className="left-modal-content">
      {/* =============== START IMAGE INFO ================ */}
      <div className="image-content">
        <img src={image} alt="imageModal" className="w-100  object-fit-cover" />
      </div>
      {/* =============== START IMAGE INFO ================ */}
      <div className="right-content-details-pro">
      {/* ================ START INFO DETAILS ================ */}
      <div className="info-details">
        <h2 className="title nexaBold-font"> {i18n.language === "ar"
              ? property.title_ar
              : property.title_en}</h2>
        <p className="text">{i18n.language === "ar"
                ? property.city.name_ar
                : property.city.name_en}</p>
        <ul className="list-items-info p-0 m-0   d-flex align-items-center gap-3">
          <li>
            <div className="btn-main btn-transparent-2">
              <BedIcons /> {property.bedNum}
            </div>
          </li>
          <li>
            <div className="btn-main btn-transparent-2">
              {" "}<Bathtub /> {property.bathNum}
            </div>
          </li>
          <li>
            <div className="btn-main btn-transparent-2">
              {" "}<SquareIcon /> {property.space} {t("squareMeters")}
            </div>
          </li>
        </ul>
        <div className="price-content price-end d-flex align-items-center gap-1">
          <sub>egp</sub>{" "}
          <span className="price-num nexaBold-font">
            {" "}{formatNumber(property.price)}
          </span>
        </div>
    
      </div>
      {/* ================ END INFO DETAILS ================ */}
    </div>
    </div>
  );
};

export default LeftModalContent;
