import ContainerMedia from "Components/ContainerMedia/ContainerMedia";
import "./HeaderDetailsProperties.css";
import ButtonCopyShare from "Components/Ui/ButtonCopyShare/ButtonCopyShare";
const HeaderDetailsProperties = ({property,i18n}) => {
  // THIS LINK COPY ON CLIKED BUTTON TO SHARE ON SOCIAL MEDIA
  const linkToCopy = `https://aqar-egypt.com/detailsProperties/${property.id}`;
  return (
    <div className="header-details-properties">
      {/* =============== START CONTAINER ============== */}
      <ContainerMedia>
        {/* =============== START ALL HEADER DETAIILS PROPERTIES ============== */}
        <div className="all-header-details-properties d-flex justify-content-between align-items-center flex-wrap gap-3">
          {/* ================ START LEFT CONTENT DETAILS PROPERTIES ============== */}
          <div className="left-header-details">
            <h2 className="title nexaBold-font">
              {i18n.language === "ar"
                ? property.title_ar
                : property.title_en}
            </h2>
           
            <p className="text"> {i18n.language === "ar"
                ? property.city.name_ar
                : property.city.name_en}</p>
          </div>
          {/* ================ END LEFT CONTENT DETAILS PROPERTIES ============== */}
          {/* ================ START RIGHT INFO DETAILS =============== */}
          <div className="right-info-details position-relative">
            <ButtonCopyShare link={linkToCopy} />
          </div>
          {/* ================ END RIGHT INFO DETAILS =============== */}
        </div>
        {/* =============== END ALL HEADER DETAIILS PROPERTIES ============== */}
      </ContainerMedia>
      {/* =============== END CONTAINER ============== */}
    </div>
  );
};

export default HeaderDetailsProperties;
