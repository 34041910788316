import { createContext, useContext, useEffect, useState } from "react";
import { auth } from "../Firebase/FirebaseConfig";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { toast } from "react-toastify";
// CREATE AUTH CONTEXT
const AuthContext = createContext();
//  USE AUTH CONTEXT
export const useAuth = () => useContext(AuthContext);
// AUTH PROVIDER COMPONENT
export const AuthProvider = ({ children }) => {
  // STATE  THE CURRENT USER
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true); // LOADING WHEN LOGIN
  // EFFECT TO SUBSCRIBE TO AUTH STATE CHANGES
  useEffect(() => {
    const unUser = onAuthStateChanged(auth, (user) => {
      if (user) {
        // SET CURRENT USER WHEN AUTH STATE CHANGES
        setCurrentUser(user);
        localStorage.setItem("loggedInUser", JSON.stringify(user)); // ADD USER IN LOCAL STOGE
      } else {
        setCurrentUser(null);
        localStorage.removeItem("loggedInUser"); // REMOVE FROM LOACAL STOREGE
      }
      setLoading(false); // UPDATE LOADINNG
    });

    return unUser;
  }, []);

  // LOGOUT REMOVE FROM LOCAL STORGE 
  const logout = () => {
    signOut(auth).then(() => {
      setCurrentUser(null);
      localStorage.removeItem("loggedInUser"); //LOGOUT REMOVE FROM LOCAL STORGE
      toast.success("Success Logout")
    });
  };

  //LOADEING BEFORE LOGIN
  if (loading) {
    return <></>;
  }

  return (
    <AuthContext.Provider value={{ currentUser, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
