import ContainerMedia from "Components/ContainerMedia/ContainerMedia";
import FilterCategory from "Components/Ui/FilterCategory/FilterCategory";

const HeaderFilter = ({showSearchResults}) => {
  return (
    <ContainerMedia>
      <div className="filter-header-info  text-center">
        <FilterCategory  showSearchResults={showSearchResults}/>
      </div>
    </ContainerMedia>
  );
};

export default HeaderFilter;
