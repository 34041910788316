import ContainerMedia from "Components/ContainerMedia/ContainerMedia";
import image from "../../../../assets/images/about/01.png";
import "./HeaderAbout.css";
import { useTranslation } from "react-i18next";
const HeaderAbout = () => {
  // FOR TRANSLATION AR && EN
  const { t } = useTranslation();
  return (
    <div className="header-about">
      {/* =============== START CONTAINER ================== */}
      <ContainerMedia>
        {/* ================ START ALL HEADER ABOUT ============ */}
        <div className="all-header-about">
          {/* ============= START INFO TOP CONTENT ============= */}
          <div className="info-top-content">
            <h2 className="title-text text-500">{t("aboutTitlePage")}</h2>
            <h2 className="title nexaBold-font">
              {t("We’re the future of real estate")}
            </h2>
            <p className="text text-500">{t("textContetnHeaderAbout")}</p>
          </div>
          {/* ============= END INFO TOP CONTENT ============= */}
          {/* ============= START IMAGE ABOUT HEADER ========== */}
          <div className="image-about-header position-relative overlay-bg-image">
            <img
              src={image}
              alt="imageAbout"
              className="w-100 object-fit-cover"
              height="470px"
            />
          </div>
          {/* ============= END IMAGE ABOUT HEADER ========== */}
        </div>
        {/* ================ END ALL HEADER ABOUT ============ */}
      </ContainerMedia>
      {/* =============== END CONTAINER ================== */}
    </div>
  );
};

export default HeaderAbout;
