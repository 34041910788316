import React from 'react'

const ParkingIcon = () => {
  return (
    <><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.51 2.82812H8.49C6 2.82812 5.45 4.06813 5.13 5.58813L4 10.9981H20L18.87 5.58813C18.55 4.06813 18 2.82812 15.51 2.82812Z" stroke="#969595" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M21.9888 19.82C22.0988 20.99 21.1588 22 19.9588 22H18.0788C16.9988 22 16.8488 21.54 16.6588 20.97L16.4588 20.37C16.1788 19.55 15.9988 19 14.5588 19H9.43876C7.99876 19 7.78876 19.62 7.53876 20.37L7.33876 20.97C7.14876 21.54 6.99876 22 5.91876 22H4.03876C2.83876 22 1.89876 20.99 2.00876 19.82L2.56876 13.73C2.70876 12.23 2.99876 11 5.61876 11H18.3788C20.9988 11 21.2888 12.23 21.4288 13.73L21.9888 19.82Z" stroke="#969595" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M4 8H3" stroke="#969595" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M21 8H20" stroke="#969595" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12 3V5" stroke="#969595" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.5 5H13.5" stroke="#969595" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6 15H9" stroke="#969595" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15 15H18" stroke="#969595" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    </>
  )
}

export default ParkingIcon