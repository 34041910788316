import BedIcons from "../../../assets/images/products/icons/BedIcons";
import Bathtub from "../../../assets/images/products/icons/Bathtub";
import SquareIcon from "../../../assets/images/products/icons/SquareIcon";
import "./CardProduct.css";
import CheckReadyIcon from "assets/images/iconsSvg/CheckReadyIcon";
import { useTranslation } from "react-i18next";

const CardProduct = ({
  imageCard,
  title,
  isReadyMoved,
  dateInfo,
  bedNumText,
  bathtubText,
  squareText,
  typePrice,
  priceInfo,
  yearsOfInstallments,
  downPayment,
  city
}) => {
  
  const { t } = useTranslation();
  const formatNumber = num => {
    if (num || num === 0) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return ''; 
  };

  return (
    <div className="card-product-one h-100">
      {/* ============== START IMAGE CARD PRODUCT =============== */}
      <div className="image-card-product position-relative overlay-bg-image">
        <img
          src={`${process.env.REACT_APP_IMAGE_URL}/${imageCard}`}
          alt="imageCard"
          className="w-100 object-fit-cover"
          height={"211px"}
        />
      </div>
      {/* ============== END IMAGE CARD PRODUCT =============== */}
      {/* ============== START DETAILS CARD PRODUCT ================ */}
      <div className="details-card-product">
        <h2 className="title  nexaBold-font">
          {title}
        </h2>
        <p className="text-content">
          {city}
        </p>

        {isReadyMoved &&
          <div className="badge-info-text d-flex align-items-center  gap-2">
            <CheckReadyIcon /> {t("Ready to move")}
          </div>}
        {!isReadyMoved &&
          <div className="delivery-date d-flex align-items-center gap-2">
           { t("Delivery date")}: <span className="date-info">{dateInfo} </span>
          </div>}
        
          <div className="down-payment d-flex align-items-center gap-2">
            <span>{downPayment}%</span>  { t("Down Payment")} / <span>{yearsOfInstallments}</span> { t("Years")} 
          </div>
        {/* ============= START DETAILS PRODUCT =========== */}
        <div className="details-product d-flex align-items-center flex-wrap gap-4">
          {/* ================ START DETAILS CONTENT ============= */}
          <div className="details-one-content d-flex align-items-center gap-1">
            <div className="icon-svg">
              <BedIcons />
            </div>{" "}
            <span className="text-info-details">{bedNumText}</span>
          </div>
          {/* ================ END DETAILS CONTENT ============= */}
          {/* ================ START DETAILS CONTENT ============= */}
          <div className="details-one-content d-flex align-items-center gap-1">
            <div className="icon-svg">
              <Bathtub />
            </div>{" "}
            <span className="text-info-details">{bathtubText}</span>
          </div>
          {/* ================ END DETAILS CONTENT ============= */}
          {/* ================ START DETAILS CONTENT ============= */}
          <div className="details-one-content d-flex align-items-center gap-1">
            <div className="icon-svg">
              <SquareIcon />
            </div>{" "}
            <span className="text-info-details">{squareText}</span>
          </div>
          {/* ================ END DETAILS CONTENT ============= */}
        </div>
        {/* ============= END DETAILS PRODUCT =========== */}
        <div className="price-content d-flex align-items-center gap-1">
          <sub>{typePrice}</sub>{" "}
          <span className="price-num nexaBold-font">
            {" "}{formatNumber(priceInfo)}
          </span>
        </div>
      </div>
      {/* ============== END DETAILS CARD PRODUCT ================ */}
    </div>
  );
};

export default CardProduct;
