

const SquareIcon = () => {
  return (
    <>
      <svg
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.99967 15.1668H9.99967C13.333 15.1668 14.6663 13.8335 14.6663 10.5002V6.50016C14.6663 3.16683 13.333 1.8335 9.99967 1.8335H5.99967C2.66634 1.8335 1.33301 3.16683 1.33301 6.50016V10.5002C1.33301 13.8335 2.66634 15.1668 5.99967 15.1668Z"
          stroke="#212121"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M4.4668 6.67334L8.00012 8.72001L11.5068 6.68667"
          stroke="#212121"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8 12.3467V8.71338"
          stroke="#212121"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.1735 4.6934L5.04016 5.88007C4.56016 6.14673 4.16016 6.82007 4.16016 7.3734V9.6334C4.16016 10.1867 4.55349 10.8601 5.04016 11.1267L7.1735 12.3134C7.62684 12.5667 8.3735 12.5667 8.8335 12.3134L10.9668 11.1267C11.4468 10.8601 11.8468 10.1867 11.8468 9.6334V7.36674C11.8468 6.8134 11.4535 6.14007 10.9668 5.8734L8.8335 4.68673C8.3735 4.4334 7.62684 4.4334 7.1735 4.6934Z"
          stroke="#212121"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};

export default SquareIcon;
