import { Formik, Form, ErrorMessage } from "formik";
import InputField from "Components/Forms/InputField";
import TextAreaInput from "Components/Forms/TextArea";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useTranslation } from "react-i18next";
import { usePhoneNumber } from "Shared/ContactUs/Components/ComponentForm/usePhoneNumber";
import { getValidationSchema } from "Shared/ContactUs/Components/ComponentForm/getValidationSchema";
import { handleFormSubmit } from "Shared/ContactUs/Components/ComponentForm/handleFormSubmit";
import { database } from "../../../../Firebase/FirebaseConfig";
import { useParams } from "react-router-dom";

const RightModalContent = ({ hideModalDetails, buttonShowModalSuccess }) => {
  // TRANLATE AR && EN
  const { t } = useTranslation();
  const {id}=useParams()
  // RESABLE COMPONET SHERD
  const { phone, handlePhoneChange, setPhone } = usePhoneNumber();
  const validationSchema = getValidationSchema(t); // VALIDYION SHERD

  const initialValues = {
    name: "",
    phone: "",
    message: ""
  };

  return (
    <div className="right-modal-content">
      <div className="form-contact-content form-style form-style--1">
        <div className="top-header-form-info">
          <h2 className="title nexaBold-font">
            {t("Interested in this property")}
          </h2>
          <p className="text text-500">
            {t(
              "Fill out the form below, and one of our representatives will contact you shortly to provide more details and answer any questions you might have"
            )}
          </p>
        </div>
        <div className="all-form-contact">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, actions) =>
              handleFormSubmit(
                {values,
                  resetForm: actions.resetForm,
                  buttonShowModalSuccess,
                  hideModalDetails: setPhone,
                  propertyId: id}
              )
            }
          >
            {({ setFieldValue }) => (
              <Form>
                <InputField
                  label={t("nameLabelContact")}
                  name="name"
                  type="text"
                  placeholder=""
                  success
                />
                <div className="input-phone-number">
                  <label htmlFor="phone" className="form-label mb-1 d-none" />
                  <PhoneInput
                    country={"eg"}
                    value={phone}
                    onChange={(value) =>
                      handlePhoneChange(value, setFieldValue)
                    }
                    containerClass="custom-container w-100"
                    inputClass="form-control w-100"
                  />
                  <ErrorMessage
                    name="phone"
                    component="div"
                    className="error"
                  />
                </div>
                <TextAreaInput
                  label={t("messageLabelContact")}
                  name="message"
                  type="text"
                  placeholder=""
                  success
                />
                <button
                  type="submit"
                  className="btn-main btn-submit btn-height mt-3"
                >
                  {t("Express interest")}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default RightModalContent;
