
import LogoInfo from './LogoInfo'
import logoBlue from "../../assets/images/logo/logoBlue.svg"

const LogoBlue = () => {
  return (
    <><LogoInfo logo={logoBlue} /></>
  )
}

export default LogoBlue