import CardProduct from "Components/Ui/CardProduct/CardProduct";
import NotDataFound from "Components/Ui/NotDataFound/NotDataFound";
import { useNavigate } from "react-router-dom";
import useProperties from "../../../../hooks/useProperties"

import LoaderApi from "Components/LoaderApi/LoaderApi";
import { useTranslation } from "react-i18next";
const CardsProductsInfo = ({properties,loading}) => {
  // ROUTE NAVIGATE PAGE
  const navigate = useNavigate()
 const { i18n,t } = useTranslation();
  return (
    <>
   {loading ? (
        <LoaderApi />
        ) : (
        <div className="row g-3">
          {properties?.length > 0 ? (
            properties.map((item) => {
              return (
              
                  <div
                    key={item.id}
                    onClick={() => {
                      navigate(`/detailsProperties/${item.id}`);
                    }
                    }
                    className="col-12 col-sm-6 col-md-4 col-lg-3"
                  >
                    <CardProduct
                      imageCard={item?.photos[0]?.url}
                      title={ i18n.language === "ar"
                        ? item.title_ar
                        : item.title_en
                        }
                      downPayment={item.downPayment}
                      yearsOfInstallments={item.yearsOfInstallments}
                      bedNumText={item.bedNum}
                      bathtubText={item.bathNum}
                      squareText={`${item.space} ${t("squareMeters")}`}
                      typePrice={"egp"}
                      priceInfo={item.price}
                      isReadyMoved={item.isReadyToMove}
                      dateInfo={item.deliveryDate}
                      city={i18n.language === "ar"
                        ? item.city.name_ar
                        : item.city.name_en}
                    />
                  </div>
              );
            })
          ) : (
            <NotDataFound />
          )}
        </div>
      )}
    </>
  );
};

export default CardsProductsInfo;
