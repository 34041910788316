import React from "react";
import "./NotFound.css";
import Lottie from "lottie-react";
import notFoundPage from "./404.json";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

const NotFound = () => {
  // TRANSLATION LANGUGES
  const { i18n } = useTranslation();
  return (
    <div className="not-found-page d-flex align-items-center  justify-content-center  flex-column">
      <div className="animtion-lottie">
        <Lottie animationData={notFoundPage} />
      </div>
      <div className="content-info-error">
        <h2 className="title  text-500">
          {i18n.language === "ar" ? "الصفحة غير موجودة!" : "Page Not Found!"}
        </h2>
      </div>
      <Link to={"/"} className="btn-main btn-not-found text-500">
        <FontAwesomeIcon icon={faHome} />
        {i18n.language === "ar" ? "الصفحة الرئيسية" : "Home Page"}
      </Link>
    </div>
  );
};

export default NotFound;
