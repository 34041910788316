import React from "react";
import MapComponent from "./MapComponent";
import "./MapLocationResults.css";
import { dataCard } from "Pages/Properties/Components/AllProductsProperties/Data";
import ArrowFullScreen from "assets/images/iconsSvg/ArrowFullScreen";

const MapLocationResults = ({ handleFullScreen ,properties}) => {
  return (
    <div className="map-full position-relative">
      <div className="btn-fullscreen" onClick={handleFullScreen}>
        <ArrowFullScreen />
      </div>
      <MapComponent properties={properties} />
    </div>
  );
};

export default MapLocationResults;
