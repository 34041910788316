import axios from 'axios';

export const fetchCities = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/city`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addNewCity = async (data) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/city`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};