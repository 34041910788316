import SectionTitle from "Components/Ui/SectionTitle/SectionTitle";
import CardInfoHowWork from "./Components/CardInfoHowWork/CardInfoHowWork";

import NotDataFound from "Components/Ui/NotDataFound/NotDataFound";
import { useTranslation } from "react-i18next";
const CardsHowWork = () => {
  // TRANSLATION AR && EN
  const { t } = useTranslation();
  // ARRAY OF DATA DETIALS CARD
  const dataCardWork = [
    {
      id: 0,
      numCard: "01",
      title: t("searchforyourhome"),
      text: t("begin")
    },
    {
      id: 1,
      numCard: "02",
      title: t("contactus"),
      text: t("reach")
    },
    {
      id: 2,
      numCard: "03",
      title: t("signacontract"),
      text: t("ready")
    }
  ];

  return (
    <div className="main-cards-info-work padding-top-50">
      {/* =============== START SECTION TITLE ============== */}
      <SectionTitle
        title={t("howitworks")}
        isButtonShow={false}
        isTextAll={false}
        newxTextButton={false}
        functionButton={false}
      />
      {/* =============== END SECTION TITLE ============== */}
      {/* =============== START ALL CARD WORK ===========  */}
      <div className="all-cards-work">
        {/* =============== START ROW ============== */}
        <div className="row g-3">
          {dataCardWork.length > 0 ? (
            dataCardWork.map((item) => (
              <div className="col-12 col-sm-6 col-md-4" key={item.id}>
                <CardInfoHowWork
                  numCard={item.numCard}
                  titleCard={item.title}
                  textCard={item.text}
                />
              </div>
            ))
          ) : (
            <NotDataFound />
          )}
        </div>
        {/* =============== END ROW ============== */}
      </div>
      {/* =============== END ALL CARD WORK ===========  */}
    </div>
  );
};

export default CardsHowWork;
