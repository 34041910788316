import HelmetInfo from "Components/HelmetInfo/HelmetInfo";
import "./ContactUsPage.css";
import ImageHeader from "./Components/ImageHeader/ImageHeader";
import FormContactPage from "./Components/FormaContactPage/FormContactPage";
import { useTranslation } from "react-i18next";

const ContactUsPage = () => {
  // FOR TRANSLATION AR && EN
  const { t } = useTranslation();
  return (
    <>
      <HelmetInfo titlePage={t("contact")} />
      <div className="contact-page padding-top-50">
        {/* ============ START HEADER ============ */}
        <header>
          <ImageHeader />
        </header>
        {/* ============ END HEADER ============ */}
        {/* ============ START MAIN ============= */}
        <main>
          <FormContactPage />
        </main>
        {/* ============ END MAIN ============= */}
      </div>
    </>
  );
};

export default ContactUsPage;
