import { useState } from "react";
import DownloadIcon from "assets/images/iconsSvg/DownloadIcon";
import "./ButtonCopyShare.css";

import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const ButtonCopyShare = ({ link }) => {
  const { t } = useTranslation();
  const [copySuccess, setCopySuccess] = useState(false);

  const copyToClipboard = async () => {

    try {
      await navigator.clipboard.writeText(link); 
      await navigator.share({
        title: "Aqar",
        text: "Why don’t you check out this property on Aqar Egypt? I think you’ll like it! ",
        url: `${link}`
    })
      setCopySuccess(!copySuccess); 
      if (copySuccess === false) {
        toast.success("Link copied to clipboard");
      }
    } catch (err) {
      setCopySuccess(false);
    }
  };

  return (
    <div className="all-content-copy position-relative">
      <button className="btn-main btn-transparent" onClick={copyToClipboard}>
        {t("Share")} <DownloadIcon />
      </button>
    </div>
  );
};

export default ButtonCopyShare;
