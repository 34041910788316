import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const MessagesTable = ({
  messages,
  currentPage,
  itemsPerPage,
  handleDeleteMessage,
  handleContactedChange
}) => {
   const navigate=useNavigate()
  const formatDate=(date)=>{
    const formatedDate = new Date( date);
    return `${formatedDate.getDate()}/${formatedDate.getMonth()+1}/${formatedDate.getFullYear()}`
  }
  return (
    <div className="table-content-info table-content-info-message">
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Name</th>
            <th>Phone</th>
            <th>Message</th>
            <th>Time</th>
            <th>Contacted</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {messages?.map((message) => (
            <tr key={message.id}>
              <td className="message-cell">{message.name}</td>
              <td className="message-cell">{message.phoneNumber}</td>
              <td className="message-cell">{message.message}</td>
              <td className="message-cell">{formatDate(message.createdAt)}</td>
              <td>
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={message.contacted || false}
                  onChange={(e) =>
                    handleContactedChange(message.id, e.target.checked)
                  }
                />
              </td>
              <td className="d-flex gap-2">
             {message.propertyId &&   <button
                  className="bg-primary text-white p-2 rounded"
                  onClick={() => navigate("/detailsProperties/"+message.propertyId)}
                >
                  View Property
                </button>}
                <div
                  className="remove-btn"
                  onClick={() => handleDeleteMessage(message.id)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MessagesTable;
